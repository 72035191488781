import axios from "axios";
import queryString from "query-string";
import Sentry from "@clcdev/gatsby-plugin-sentry";

class TwilioVerify {
    constructor() {
        this.twilioTokens = {};
        this.options = {};

        this.setTwilioTokens = this.setTwilioTokens.bind(this);
        this.sendVerificationCode = this.sendVerificationCode.bind(this);
        this.checkVerificationCode = this.checkVerificationCode.bind(this);
    }

    setTwilioTokens(tokens) {
        this.twilioTokens = tokens;
        this.options = {
            auth: {
                username: this.twilioTokens.verifyApiKey,
                password: this.twilioTokens.verifyApiSecret,
            },
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
    }

    async sendVerificationCode(contactDetail, channel) {
        try {
            const {
                verifyApiUrl,
                serviceSid,
            } = this.twilioTokens;

            const body = {
                To: contactDetail, // phone number OR email
                Channel: channel, // "sms" | "email" | "call"
            };
            const data = queryString.stringify(body);
            const URL = `${verifyApiUrl}/${serviceSid}/Verifications`;

            const response = await axios.post(URL, data, this.options);
            // console.log("sendVerificationCode response:", response);
            return response.data.status;
        } catch (err) {
            console.error("Unable to send twilio verification code:", err);
            Sentry.captureException(`Unable to send twilio verification code: ${err}`);
        }
    }

    async checkVerificationCode(contactDetail, code) {
        try {
            const {
                verifyApiUrl,
                serviceSid,
            } = this.twilioTokens;

            const body = {
                Code: code,
                To: contactDetail,
            };
            const data = queryString.stringify(body);
            const URL = `${verifyApiUrl}/${serviceSid}/VerificationCheck`;

            const response = await axios.post(URL, data, this.options);
            // console.log("checkVerificationCode response:", response);
            return response.data.status;
        } catch (err) {
            console.error("Unable to check twilio verification code:", err);
            Sentry.captureException(`Unable to check twilio verification code: ${err}`);
        }
    }
}

const _twilioVerify = new TwilioVerify();
const setTwilioTokens = _twilioVerify.setTwilioTokens;
const sendVerificationCode = _twilioVerify.sendVerificationCode;
const checkVerificationCode = _twilioVerify.checkVerificationCode;

export { setTwilioTokens, sendVerificationCode, checkVerificationCode };
export default _twilioVerify;
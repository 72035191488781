import "../assets/talon";

export const callEhawkTalon = () => {
    return new Promise((resolve, reject) => {
        const eHawkTalon = window.eHawkTalon;
        if (eHawkTalon){
            eHawkTalon(
                { oncomplete: (talon) => resolve(talon) }, // 'talon' is the fingerprint value
                undefined,
                false,
                {
                    bind: { // set OutClass and OutId to empty values to avoid mutating hidden input in DOM
                        OutClass: "",
                        OutId: "",
                    },
                },
            );
        } else {
            reject("talon script imported and resolved but eHawkTalon function not ready/parsed...");
        }
    });
};
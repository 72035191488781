"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRecaptchaValidator = exports.executeRecaptchaV3 = void 0;
const recaptchaReady_1 = require("./recaptchaReady");
const sanitizeAction = (action) => {
    return action.replace(/-|\W/g, "_");
};
const executeRecaptchaV3 = async ({ action, key, }) => {
    await recaptchaReady_1.recaptchaReady;
    return grecaptcha.execute(key, {
        action: sanitizeAction(action),
    });
};
exports.executeRecaptchaV3 = executeRecaptchaV3;
const getRecaptchaValidator = () => {
    const recaptchaResultCache = {};
    const doValidate = async ({ action, key, force = false, apiUrl, }) => {
        if (force || !(action in recaptchaResultCache)) {
            const recaptchaToken = await (0, exports.executeRecaptchaV3)({
                action,
                key,
            });
            const response = await fetch(apiUrl, {
                method: "post",
                body: JSON.stringify({
                    token: recaptchaToken,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const results = await response.json();
            if (response.status !== 200 || results.success !== true) {
                delete recaptchaResultCache[action];
                throw results;
            }
            recaptchaResultCache[action] = results;
        }
        return recaptchaResultCache[action];
    };
    return doValidate;
};
exports.getRecaptchaValidator = getRecaptchaValidator;

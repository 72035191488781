import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

import { config } from "config";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import BackNavigation from "./BackNavigation";
import ContactUs from "./ContactUs";
import partnerCampaignsJSON from "../data/partner-campaigns.json";
import CampaignHandler from "../utils/campaign-handler";

const useStyles = makeStyles((theme) => ({
    appBarHeight: {
        height: "65px",
    },
    logosBreakPoint: {
        [theme.breakpoints.down("xs")]: {
            textAlign: "center",
        },
    },
}));

const MuiToolbar = withStyles((theme) => ({
    root: {
        position: "relative",
        justifyContent: "center",
    },
}))(Toolbar);
const StyledAppBar = withStyles((theme) => ({
    root: {
        background: "white",
        position: "relative",
        height: "100%",
        justifyContent: "center",
    },
}))(MuiAppBar);

const Logos = (props) => {
    const { hideBranding } = props;

    const imageData = useStaticQuery(query);
    const [logos, setLogos] = useState(() =>
        [
            !hideBranding && {
                props: imageData.appLogo.childImageSharp,
                url: config.homeUrl,
            },
        ].filter(Boolean),
    );

    useEffect(() => {
        const campaignData = partnerCampaignsJSON[CampaignHandler.campaignId];
        if (campaignData && !hideBranding) {
            setLogos((curPartnerLogos) =>
                curPartnerLogos.concat({
                    props: {
                        ...imageData[campaignData.imageName].childImageSharp,
                        imgStyle: {
                            objectFit: "scale-down",
                        },
                    },
                    url: campaignData.url,
                }),
            );
        }
    }, [hideBranding, imageData]);

    return (
        <>
            {logos.map((logo, i) => (
                <Box
                    key={i}
                    style={
                        i % 2 === 0
                            ? { width: "100%" }
                            : { width: "100%", marginLeft: "20px" }
                    }
                >
                    <Img
                        {...logo.props}
                        loading="eager"
                        alt="Company Logo"
                        durationFadeIn={0}
                    />
                </Box>
            ))}
        </>
    );
};

const AppBar = (props) => {
    const { stepName } = props;

    const { hideBranding, showContactUs } = useSiteMetadata();

    const styles = useStyles();
    const indexStepName = Object.keys(config.steps)[0];
    const hideBackNavigation =
        stepName === indexStepName || stepName === "success";

    return (
        <Box className={styles.appBarHeight}>
            <StyledAppBar>
                <MuiToolbar>
                    <BackNavigation
                        text="Back"
                        hideBackNavigation={hideBackNavigation}
                    />
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        className={styles.logosBreakPoint}
                    >
                        <Logos hideBranding={hideBranding} />
                    </Box>
                    {showContactUs && <ContactUs />}
                </MuiToolbar>
            </StyledAppBar>
        </Box>
    );
};

export const CoLogo = graphql`
    fragment CoLogo on File {
        childImageSharp {
            fixed(width: 110) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
            }
        }
    }
`;

export const query = graphql`
    {
        appLogo: file(name: { eq: "bbc46e51-6c15-526b-8044-b5cfc4da8888" }) {
            childImageSharp {
                fixed(width: 150) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                }
            }
        }
        creditVerifyLogo: file(relativePath: { eq: "credit_verify_logo.png" }) {
            ...CoLogo
        }
        smarterLoansLogo: file(relativePath: { eq: "smarter-loans-logo.png" }) {
            ...CoLogo
        }
        borrowellLogo: file(relativePath: { eq: "borrowellLogo.png" }) {
            ...CoLogo
        }
        GreedyRatesLogo: file(relativePath: { eq: "GreedyRatesLogo.png" }) {
            ...CoLogo
        }
        leasecostsLogo: file(relativePath: { eq: "leasecostsLogo.png" }) {
            ...CoLogo
        }
        moneySenseLogo: file(relativePath: { eq: "moneySenseLogo.png" }) {
            ...CoLogo
        }
        narcityLogo: file(relativePath: { eq: "narcityLogo.png" }) {
            ...CoLogo
        }
        savvyLogo: file(relativePath: { eq: "savvyLogo.png" }) {
            ...CoLogo
        }
        sonnetLogo: file(relativePath: { eq: "sonnetLogo.png" }) {
            ...CoLogo
        }
        hardbaconLogo: file(relativePath: { eq: "hardbaconLogo.png" }) {
            ...CoLogo
        }
        loanspotLogo: file(relativePath: { eq: "loanspotLogo.png" }) {
            ...CoLogo
        }
        usedLogo: file(relativePath: { eq: "usedLogo.png" }) {
            ...CoLogo
        }
        YoungandThriftyLogo: file(
            relativePath: { eq: "YoungandThriftyLogo.png" }
        ) {
            ...CoLogo
        }
    }
`;

export default AppBar;

import React, { useContext } from "react";
import { useApplicationState } from "./application-state";

const applicationContext = {
    data: {},
    setField: () => {},
    addObject: () => {},
};

const ApplicationContext = React.createContext(applicationContext);

export default ApplicationContext;

export const useApplicationContext = () => {
    const context = useContext(ApplicationContext);
    if (!context) {
        throw new Error("You cannot use the ApplicationContext outside of a <ApplicationContext.Provider>");
    }
    return context;
};

export const AppContextProvider = ({ children, siteOptions }) => {
    const state = useApplicationState(siteOptions);
    return <ApplicationContext.Provider value={state}>
        {children}
    </ApplicationContext.Provider>;
};
import Sentry from "@clcdev/gatsby-plugin-sentry";

export const formatDate = (date) => {
    try {
        date = new Date(date);
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = "0" + mm;
        let yyyy = date.getFullYear();
        return dd + "/" + mm + "/" + yyyy;
    } catch (err) {
        Sentry.captureException(err);
    }
};

export const formatPhoneNumber = (v) => {
    if(!v) return "";
    try {
        let trim = v.replace(/[^A-Z0-9]/ig, "");
        if (trim.length === 11)
            trim = trim.substring(1, trim.length);
        return trim;
    } catch (err) {
        Sentry.captureException(err);
    }
};
import React from "react";
import MuiModal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import { withStyles, makeStyles } from "@material-ui/styles";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Fade from "@material-ui/core/Fade";

const useContainerStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "85%",
        height: "87%",
        borderRadius: "20px",
        background: theme.palette.white,
        padding: "20px",
        outline: 0,
        margin: "auto",
        position: "relative",
        top: "60px",

        [theme.breakpoints.down(540)]: {
            top: "0px",
            width: "100%",
            height: "100%",
            padding: "15px",
        },
        [theme.breakpoints.up(1440)]: {
            width: "1200px",
        },
    },
}));
const ExitContainer = withStyles((theme) => ({
    root: {
        "&:hover": {
            cursor: "pointer",
        },
    },
}))(Box);
const TitleContainer = withStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 700,
        fontSize: "24px",
        margin: "15px 0px 10px 0px",
        textTransform: "uppercase",
    },
}))(Box);
const ContentContainer = withStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        fontSize: "18px",
        justifyContent: "center",
        margin: "10px 0px 30px 0px",
        overflowY: "scroll",
    },
}))(Box);
const ExitIcon = withStyles((theme) => ({
    root: {
        fill: theme.palette.primary.main,
    },
}))(CloseRoundedIcon);

const BaseModal = (props) => {
    const {
        open,
        handleClose,
        closeAfterTransition,
        title,
        content,
        ctaComponent,
        classes,
    } = props;

    const containerStyles = useContainerStyles();

    return (
        <MuiModal
            open={open}
            closeAfterTransition={closeAfterTransition}
        >
            <Fade in={open}>
                <Box classes={classes || containerStyles}>
                    <Box
                        style={{
                            display: "grid",
                            width: "100%",
                            justifyContent: "end",
                        }}
                    >
                        <ExitContainer onClick={handleClose}>
                            <ExitIcon />
                        </ExitContainer>
                    </Box>
                    {title && <TitleContainer>{title}</TitleContainer>}
                    <ContentContainer>
                        {content}
                    </ContentContainer>
                    {ctaComponent}
                </Box>
            </Fade>
        </MuiModal>
    );
};

export default BaseModal;
import { useEffect, useLayoutEffect, useState, useCallback, useMemo } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";

import recaptchaApi from "@clcdev/gatsby-plugin-recaptcha";
// import { useTraceUpdate } from "@clcdev/react-core/hooks/useTraceUpdate";

import { LeadspediaApi } from "@clcdev/leadspedia/client";
import { reactPlugin } from "@clcdev/leadspedia/plugins/react";
import { localStoragePlugin } from "@clcdev/leadspedia/plugins/local-storage";

import { config, setStateFetcher as setConfigStateFetcher } from "config";
import { country } from "@locales";

import { getTalonFingerprint } from "../api/ehawk-talon";
import { getClientIp } from "../api/get-client-ip";
import { sendPartialLead } from "../partialLeads";
import { subIdMappings } from "../data/app";
import { useToast } from "../hooks/use-toast";

import { useLocalStorage, storageInvalidated } from "../utils/storage-handler";
import CampaignHandler from "../utils/campaign-handler";
import { STEPS_DATA } from "../data/constants";

const isSsr = typeof window === "undefined";

const timeLeft = require("../timeleft");
const indexPage = Object.keys(config.steps)[0];

const isSSR = typeof window === "undefined";
const campaignQuery = graphql`
    query MyQuery {
        allCampaign {
            nodes {
                postCampaignKey
                postCampaignID
                campaignId
            }
        }
    }
`;

const lpApi = new LeadspediaApi({
    leadType: process.env.GATSBY_APP_TYPE,
    transformers: config.transformers,
    fieldMappings: { ...subIdMappings },
});

const leadLocalStorage = localStoragePlugin("gJ5Drt2CARLOANSCANADA_leadCache");
lpApi.use(reactPlugin, leadLocalStorage);

let checkInitialCacheInvalidation = true;

export const useApplicationState = (siteOptions) => {
    const {
        useStorageListener,
        useStorageKey,
    } = useLocalStorage();

    const defaultCampaignId = config.brandInfo.defaultCampaignId;
    const campaignData = useStaticQuery(campaignQuery);
    const campaigns = campaignData.allCampaign.nodes;
    let postCampaignID = "";
    let postCampaignKey = "";

    // Capture Campaign Data and handle tracking pixels
    if (!CampaignHandler.initialized && !isSSR) {
        CampaignHandler.init(campaigns, defaultCampaignId);
        postCampaignID = CampaignHandler.currentCampaign?.postCampaignID;
        postCampaignKey = CampaignHandler.currentCampaign?.postCampaignKey;
    }

    const getNewLead = (state) => {
        const lead = lpApi.new(state, {
            campaign: {
                id: CampaignHandler.cid,
                postId: postCampaignID,
                postKey: postCampaignKey,
            },
            requiredPartialFields: config.requiredPartialFields,
            test: process.env.GATSBY_ACTIVE_ENV === "development",
        });

        setConfigStateFetcher(() => lead.state);
        return lead;
    };

    /**
     * @type {[import("@clcdev/leadspedia/types").Lead]}
     */
    const [lead, changeLead] = useState(() => getNewLead());

    const { addToastError, removeToastError } = useToast();

    const [viewState, setViewState] = useStorageKey("viewState", {
        manualAddress: false,
    });
    const [path, setPath] = useStorageKey("pathData", {});
    const [stepsData, setSteps] = useStorageKey(STEPS_DATA, [indexPage], (cachedSteps) => {
        return cachedSteps.every((step) => step in config.steps);
    });
    const [partialSent, setPartialSent] = useStorageKey("partialSent", false);
    const [RecaptchaWidget, validateRecaptcha] = siteOptions.recaptcha
        ? recaptchaApi.useRecaptchaWidget({ autoValidate: false })
        : [null, () => Promise.resolve()];

    // Lp Lead State
    /* Get the field value in the lead's state */
    const getLeadField = useCallback((name) => {
        return lead.fields.get(name);
    }, [lead]);

    /* Get lead meta data */
    const getLeadMeta = useCallback(() => {
        return lead.meta;
    }, [lead]);

    /* updates a specific field in the Lead instance state */
    const updateLeadField = useCallback((name, value) => {
        lead.fields.set(name, value);
    }, [lead]);

    /* updates the Lead instance state */
    const updateLeadState = useCallback((state) => {
        lead.merge(state);
    }, [lead]);

    /* updates the lead in LP */
    const updateLead = useCallback((state) => {
        lead.update(state);
    }, [lead]);

    /* approves & attempts to post a partial lead */
    const approvePartialLead = useCallback(async () => {
        await appendPostData();
        const result = await lead.approveAndAttemptPost().promise();
        return result;
    }, [lead]);

    const appendPostData = useCallback(async () => {
        const recaptchaFailed = await validateRecaptcha()
            .then(() => false)
            .catch(() => true);

        const talon = await getTalonFingerprint;
        const ipAddress = await getClientIp();

        lead.merge({
            // App Fields
            appVersion: config.version,
            country: country || "CA",

            // Campaign & UTM Data
            ...CampaignHandler.campaignData,
            ...CampaignHandler.utmData,

            // Ehawk
            talon,

            // Browser State
            ipAddress,
            userAgent: navigator.userAgent,
            recaptchaFailed: recaptchaFailed,
            isBrowser: true,
        });
    }, [lead, validateRecaptcha]);

    const postLead = useCallback(async () => {
        await appendPostData();
        const result = await lead.post().promise();
        return result;
    }, [lead]);

    // View State
    const updateViewState = useCallback((key, value) => {
        setViewState((currViewState) => ({
            ...currViewState,
            [key]: value,
        }));
    }, [setViewState]);

    const checkPartialLead = useCallback((_) => {
        if (partialSent) return;

        if (
            lead.state.firstName &&
            lead.state.lastName &&
            lead.state.email
        ) {
            sendPartialLead(lead.state);
            setPartialSent(true);
        }
    }, [lead, sendPartialLead, setPartialSent]);

    // Step Data
    const addStepToStepsData = useCallback((step) => {
        setSteps([...stepsData, step]);
        checkPartialLead();
    }, [stepsData, setSteps, checkPartialLead]);

    const clearContext = useCallback(() => {
        setViewState({});
        setPath({});
        setSteps([indexPage]);
        setPartialSent(false);
        leadLocalStorage.clearCache();
        changeLead(getNewLead());
    }, [setViewState, setPath, setSteps, setPartialSent, leadLocalStorage, changeLead]);

    const handleCacheInvalidation = useCallback((clear = true) => {
        // Reset invalidated state
        storageInvalidated(false);

        clear && clearContext();
        navigate(`/${location.search}${location.hash}`);
        addToastError("Oops! There was a problem. We've had to restart your app");
    }, [clearContext, addToastError]);

    useStorageListener("invalidated", handleCacheInvalidation);

    useLayoutEffect(() => {
        if (checkInitialCacheInvalidation && storageInvalidated()) handleCacheInvalidation(false);
        // Only check for initial cache invalidation one time
        checkInitialCacheInvalidation = false;
    }, [handleCacheInvalidation]);

    // Handle App Path State
    useEffect(() => {
        const p = timeLeft.getStep(indexPage, lead.state, config.steps, 0);
        setPath(p);
    }, []);

    const buildSchema = useCallback((page, time) => {
        if (!time)
            time = 0;
        setPath(timeLeft.getStep(page, lead.state, config.steps, time));
    }, [setPath]);

    const recaptcha = useMemo(() => ({
        RecaptchaWidget,
        validateRecaptcha,
    }), [RecaptchaWidget, validateRecaptcha]);

    const context = useMemo(() => ({
        lead,
        getLeadField,
        updateLeadField,
        updateLeadState,
        getLeadMeta,
        appendPostData,
        postLead,
        updateLead,
        approvePartialLead,

        addToastError,
        removeToastError,

        viewState,
        updateViewState,

        path,
        setPath,
        buildSchema,

        stepsData,
        setSteps,
        addStepToStepsData,

        clearContext,

        recaptcha,
        useStorageKey,
    }), [
        lead,
        getLeadField,
        updateLeadField,
        updateLeadState,
        getLeadMeta,
        appendPostData,
        postLead,
        updateLead,
        approvePartialLead,
        addToastError,
        removeToastError,
        viewState,
        updateViewState,
        path,
        setPath,
        buildSchema,
        stepsData,
        setSteps,
        addStepToStepsData,
        clearContext,
        recaptcha,
        useStorageKey,
    ]);

    // useTraceUpdate("Context", context);

    return context;
};
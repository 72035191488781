import pixelConfig from "__PIXEL_CONFIG__";
import { storageHandler } from "./storage-handler";
import { getQueryParams } from "./get-query-params";
import { filterKeysFromObj } from "./filter-object-keys";
import { normalize } from "../utils/normalize";
import { withPrefix } from "gatsby";
import firePostback from "../api/fire-postback";
import Sentry from "@clcdev/gatsby-plugin-sentry";

const DEFAULT_CAMPAIGN_ID = "21";
const isSsr = typeof window === "undefined";
const isIndexStep = !isSsr && window.location.pathname === withPrefix(normalize("/"));
const subIdFields = (new Array(5)).fill("").map((_, i) => `s${i + 1}`);
const utmFields = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "ad"];
const queryParams = !isSsr ? getQueryParams("campaignId", ...subIdFields) : {};
const utmQueryParams = !isSsr ? getQueryParams(...utmFields) : {};

const campaignKeyMap = {
    "postCampaignID": "lp_campaign_id",
    "postCampaignKey": "lp_campaign_key",
};

class CampaignHandler {
    constructor() {
        this.cid = null;
        this._campaignData = {};
        this._utmData = {};
        this.currentCampaign = null;
        this.campaigns = [];
        this.leadResponse = null;
        this.initialized = false;
        this.storageHandler = null;
        this.utmStorageHandler = null;
        this.pixelsList = [];

        this.init = this.init.bind(this);
        this.injectPixel = this.injectPixel.bind(this);
        this.getCampaignDetails = this.getCampaignDetails.bind(this);
        this.clearCampaignData = this.clearCampaignData.bind(this);
        this.clearUtmData = this.clearUtmData.bind(this);
    }

    getCampaignDetails() {
        const cachedCampaignData = this.storageHandler.get();
        console.log(cachedCampaignData);
        if (isIndexStep) return queryParams.campaignId ? queryParams : cachedCampaignData;
        if (cachedCampaignData.campaignId) return cachedCampaignData;
        return queryParams;
    }

    getUtmParams() {
        const cachedUtmData = this.utmStorageHandler.get();
        const hasData = utmFields.some((utmField) => utmField in utmQueryParams);
        if (isIndexStep) return (hasData) ? utmQueryParams : cachedUtmData;
        if (cachedUtmData["utm_campaign"]) return cachedUtmData;
        return utmQueryParams;
    }

    init(campaigns, defaultCampaignId = DEFAULT_CAMPAIGN_ID) {
        if (!this.initialized) {
            this.storageHandler = storageHandler.getKeyHandler("campaignData", {});
            this.utmStorageHandler = storageHandler.getKeyHandler("utmData", {});

            // utm
            this._utmData = this.getUtmParams();

            this.campaigns = campaigns;

            let {
                campaignId = defaultCampaignId,
                ...subIds
            } = this.getCampaignDetails();

            this._campaignData = {
                campaignId,
                ...subIds,
            };

            this.setCampaignId(campaignId);
            this.currentCampaign = campaigns.find((campaign) => campaign.campaignId === campaignId);
            console.log(defaultCampaignId, campaignId, this.currentCampaign);
            this.pixelsList = [0, campaignId];

            this.onEntry();

            this.storageHandler.set(this._campaignData);
            this.utmStorageHandler.set(this._utmData);

            this.initialized = true;
        }
    }

    get campaignId() {
        return this.cid;
    }
    setCampaignId(cid) {
        this.cid = cid;
    }

    setLeadResponse(response) {
        this.leadResponse = response;
    }

    injectPixel(html, position) {
        try {
            const domParser = new DOMParser();
            const tempElem = domParser.parseFromString(`<body>${html}</body>`, "text/html");

            tempElem.body.childNodes.forEach((childNode, i) => {
                if (childNode.nodeName === "#text") return;
                // create dom element for pixel (script, iframe, etc)
                const elem = document.createElement(childNode.tagName);
                // set attributes
                for (const attribute of childNode.attributes) {
                    elem.setAttribute(attribute.name, attribute.value);
                }
                // set innerHTML
                elem.innerHTML = childNode.innerHTML;
                // append node to DOM which also executes logic
                document[position].appendChild(elem);
            });
        } catch (err) {
            console.warn("Error occurred during pixel injection: failed to inject pixel...");
            console.log(html);
            console.error(err);
            Sentry.captureException(err);
        }
    }

    async _lifecycleEvent(stage) {
        let defaultPosition;
        // returns an array of entries (pixel code/postbacks/actions) for target event
        const getEventEntries = (pixelId) => {
            if (!pixelConfig[pixelId]) return false;
            const pixel = pixelConfig[pixelId];
            const events = pixel.lifecycle;
            if (stage in events) {
                if (!events[stage]) {
                    return false;
                }
                const event = events[stage];
                defaultPosition = event.position || "body";
                return event.entriesList || [];
            }
        };

        const resolveProp = (property) => {
            if (typeof property === "function") {
                return property(
                    stage,
                    {
                        ...this._campaignData,
                        ...this.leadResponse,
                    },
                    {
                        isIndexPage: !isSsr &&
                            window.location.pathname === withPrefix("/"),
                    },
                );
            }
            return property;
        };

        const processEventEntries = (eventEntries) => {
            return Promise.all(eventEntries.map(async (entry) => {
                const code = entry.code && resolveProp(entry.code);
                if (code) {
                    const position = (entry.position) ? entry.position : defaultPosition;
                    if (entry.hasDocumentWriteFn) {
                        const oldWrite = document.write;
                        document.write = (html) => this.injectPixel(html, position);
                        this.injectPixel(code, position);
                        document.write = oldWrite;
                    } else {
                        this.injectPixel(code, position);
                    }
                }
                if (entry.postback) {
                    const pb = entry.postback;
                    const url = resolveProp(pb.url);
                    const payload = resolveProp(pb.payload);
                    if (url && payload) {
                        await firePostback({
                            url,
                            httpMethod: pb.httpMethod,
                            headers: pb.headers,
                            payload,
                        });
                    }
                }
                if (entry.action) {
                    // fire lifecycle event action
                    resolveProp(entry.action);
                }
            }));
        };

        const processPixels = (pixelList) => {
            return Promise.all(pixelList.map(async (pixelId) => {
                const eventEntriesList = getEventEntries(pixelId);
                if (eventEntriesList) {
                    return processEventEntries(eventEntriesList);
                }
            }));
        };

        return processPixels(this.pixelsList);
    }

    async postSubmit() {
        return this._lifecycleEvent("postSubmit");
    }

    async onEntry() {
        return this._lifecycleEvent("onEntry");
    }

    async onRouteChange() {
        return this._lifecycleEvent("onRouteChange");
    }

    // return campaign parameters needed for payload
    get campaignData() {
        return {
            ...filterKeysFromObj(this._campaignData, "campaignId"),
            ...Object.entries(
                this.currentCampaign,
            ).reduce((acc, [from, val]) => {
                const to = from in campaignKeyMap && campaignKeyMap[from];
                if (to) acc[to] = val;
                return acc;
            }, {}),
        };
    }

    get utmData() {
        return this._utmData;
    }

    clearCampaignData() {
        this.storageHandler.set({});
    }
    clearUtmData() {
        this.utmStorageHandler.set({});
    }
}

const _campaignHandler = new CampaignHandler();
const clearCampaignData = _campaignHandler.clearCampaignData;
const clearUtmData = _campaignHandler.clearUtmData;

export { clearCampaignData, clearUtmData };
export default _campaignHandler;
import React from "react";
import { navigate } from "gatsby";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

const BackButtonContainer = withStyles((theme) => ({
    root: {
        position: "absolute",
        left: "11px",
        top: "19px",

        "&:hover": {
            cursor: "pointer",
        },

        [theme.breakpoints.down(600)]: {
            top: "15px",
        },
    },
}))(Box);
const BackIconContainer = withStyles((theme) => ({
    root: {
        display: "flex",
    },
}))(Box);
const BackIcon = withStyles((theme) => ({
    root: {
        fill: `${theme.palette.primary.main} !important`,
    },
}))(ArrowBackRoundedIcon);
const BackTextContainer = withStyles((theme) => ({
    root: {
        display: "flex",
        color: theme.palette.primary.main,
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: "5px",
        ...theme.typography.subtitle2,

        [theme.breakpoints.down(600)]: {
            display: "none",
        },
    },
}))(Box);

const BackNavigation = (props) => {
    const {
        text,
        onClick,
        classes,
        className,
        hideBackNavigation,
    } = props;

    const hideStyles = {
        display: (hideBackNavigation)
            ? "none"
            : "flex",
    };

    const handleBackClick = (e) => {
        navigate(-1);
    };

    return (
        <BackButtonContainer
            onClick={handleBackClick || onClick}
            classes={classes}
            className={className}
            {...hideStyles}
        >
            <BackIconContainer>
                <BackIcon />
            </BackIconContainer>
            <BackTextContainer>
                {text}
            </BackTextContainer>
        </BackButtonContainer>
    );
};

export default BackNavigation;
import {
    useLocalStorage as useLocalStorageInstance,
    initStorageNamespace,
} from "@clcdev/react-core/hooks/useLocalStorage";
import { config } from "config";
import { CACHE_ID } from "../data/constants";

let isStorageInvalidated = false;
export const storageHandler = initStorageNamespace(CACHE_ID, config.version, {
    invalidated: () => {
        isStorageInvalidated = true;
    },
    validateOnInit: true,
});

export const storageInvalidated = (newVal) => (
    isStorageInvalidated = (
        newVal == null
            ? isStorageInvalidated
            : newVal
    )
);

export const useLocalStorage = () => (
    useLocalStorageInstance(CACHE_ID, config.version)
);
const isPlainObject = (val) => (
    typeof val === "object" && !Array.isArray(val)
);

export const setDefaultsOnObject = (obj = {}, defaultValue = {}, key) => {
    if (isPlainObject(defaultValue) && !key) {
        Object.entries(defaultValue).forEach(([key, val]) => {
            setDefaultsOnObject(obj, val, key);
        });
        return obj;
    }
    if (!key) return obj;

    const objHasKey = key in obj;
    if (!objHasKey) {
        obj[key] = defaultValue;
        return obj;
    }

    const objVal = obj[key];
    if (isPlainObject(objVal) && isPlainObject(defaultValue)) {
        setDefaultsOnObject(objVal, defaultValue);
    }
    return obj;
};

class AutocompleteReady {
    isReady;
    promise;
    resolve = () => {};

    constructor() {
        this.isReady = false;
        this.promise = new Promise((resolve) => {
            this.resolve = resolve;
            if (this.isReady) resolve();
        });
    }

    ready() {
        this.isReady = true;
        if (this.resolve) this.resolve();
    }

    then(callback) {
        this.promise.then(callback);
        return this;
    }
}

const _autocompleteReady = new AutocompleteReady();
export default _autocompleteReady;
import React from "react";

const TermsOfUseContent = ({ name, url }) => {
    const BrandName = () => (!url || url === "/")
        ? <b>{name}</b>
        : <a href={url} target="_blank" rel="noopener noreferrer">{name}</a>;

    return (
        <div>
            <h1>Terms of Use</h1>
            <h2>Introduction</h2>
            <p>
                Please read our Terms of Use carefully before using the {" "}
                <BrandName /> {" "}
                website (the &quot;Site&quot;). By using the Site, you agree to be bound
                by these Terms of Use. If you do not agree with any part of these Terms of Use,
                you must not use the Site. Your continued use of the Site will constitute your
                acceptance of these Terms of Use, as may be modified by {name} at any time without
                notice to you. Please check this page regularly for updates.
            </p>
            <p>
                As we offer services in relation to a number of products and services, there are two
                sections to our Terms of Use. Once you have read Section 1 which applies to specific products
                and services, read the relevant paragraph in Section 2, which provides general terms
                that relate to all products and services.
            </p>
            <h2>Section 1 (Terms for specific products and services)</h2>
            <p>
                Some products or services require additional terms and conditions to those set out above and these are detailed below:
            </p>
            <h3>1 Auto Loan Applications</h3>
            <p>
                Our Auto Loan Application service allows you to apply for an Auto Loan for the purpose of begin Pre-Approved from home.
            </p>
            <p>
                You will need to answer a number of questions on this website in order to compare or obtain a pre-approval or quote from one of our Dealer Partners.
                These questions are designed to ensure that we and all relevant Dealer Partners and Lenders have all the information necessary to provide you with
                appropriate and timely information relating to the products and automobiles in which you are interested in. The tier of approvals and the amount you
                will be pre-approved for and inventory that will be offered  will be determined by the answers you provide to these questions. It is therefore very
                important that you answer all of the questions truthfully, completely and accurately and that you disclose all relevant facts. Failure to do so could
                invalidate the auto loan pre-approval you are applying for.
            </p>
            <p>
                By submitting an application you consent to the collection, use, and disclosure of your personal information by Car Loans Canada for the purpose of
                providing information and offers related to the purchase of an automobile.  You also consent to Car Loans Canada, car dealers and lenders obtaining
                credit reports about you to facilitate your application for an auto loan and acknowledge that Car Loans Canada, and it’s affiliates may record all
                communication with them for quality assurance and training purposes.
            </p>
            <p>
                In order to apply for an insurance product, you must contact the relevant insurance provider. We will give you a direct link to their website and/or
                a dedicated telephone number along with any identification needed to access your details.
            </p>
            <h3>2 Financial Products</h3>
            <p>
                Our financial service allows you to search for and compare various types of products from different product providers.
            </p>
            <p>
                You will need to answer a number of questions on this website in order to compare any financial product. These questions are designed to ensure that
                we and all relevant product providers have all the information necessary to provide you with appropriate and timely information relating to the products
                in which you are interested. It is very important that you answer all of the questions truthfully, completely and accurately and that you disclose all
                relevant facts.
            </p>
            <p>
                In order to apply for a financial product or service, you must contact the relevant product provider.
            </p>
            <p>
                Before you apply for any financial product or service, it is very important that you check all of the information held by the product provider
                about you to ensure it is correct, complete and accurate. It is your responsibility to identify and correct any mistakes or errors in the information
                held by the product provider about you before you apply for any financial product or service.
            </p>
            <p>
                If you are in any doubt as to whether any information is relevant or required to be disclosed or that something may be incorrect you should disclose it
                to your proposed product provider before you apply for the money product.
            </p>
            <p>
                It is very important before you apply for any financial product or service that you carefully read the terms and conditions of the product, the product
                provider&apos;s terms and conditions and any other documentation that applies to the product. You must familiarize yourself with all the details of the product,
                for example, the interest rate, any early redemption charges, restrictions, exclusions, conditions and obligations which apply to the product. It is your
                responsibility to ensure that the product matches your requirements and that you agree to the terms and conditions of the product before you apply for it.
                We accept no responsibility or liability whatsoever for any loss or damage you may suffer or incur in the event that any product you apply for does not
                meet your requirements or is not suitable for you.
            </p>
            <h3>3 User generated content (Including discussion forums and user reviews)</h3>
            <p>
                You agree that any User Content (as described in paragraph 4.5 of Section 1) will comply with this paragraph 15 and the User Content Standards.
            </p>
            <p>
                You are not permitted to use this website to:
            </p>
            <ul>
                <li>Collect email addresses or other contact information of others for the purposes of sending unsolicited emails or other unsolicited communications.</li>
                <li>Promote or encourage illegal activity.</li>
                <li>
                    Do anything that is unlawful, harmful, fraudulent, threatening, defamatory, embarrassing, distressing, infringing, abusive, inflammatory, intimidating, harassing,
                    libelous, stalking, profane, obscene, indecent, inappropriate, hateful, discriminatory or racially, ethnically, sexually or otherwise objectionable or for the
                    purpose of harming or attempting to harm minors in any way.
                </li>
                <li>Do anything which violates the rights of others (such as rights of privacy).</li>
                <li>Impersonate any other person or falsely state or otherwise misrepresent yourself.</li>
                <li>
                    Upload, post, transmit, distribute, share, store or otherwise make publicly available any personal information of any other person including names, addresses,
                    phone numbers and email addresses.
                </li>
                <li>
                    Upload, post, transmit, distribute, modify, reproduce, share, store or otherwise make publicly available any information, material, data or content that infringes
                    any patent, trade mark, trade secret, copyright or other intellectual property right of any other person.
                </li>
                <li>
                    Send any unsolicited or unauthorized advertising, promotional materials, &apos;junk mail&apos;, &apos;spam&apos;, &apos;chain letters&apos; or any other form of
                    such solicitation.
                </li>
                <li>Post links to alternative community groups or forums except with our prior written consent.</li>
            </ul>
            <p>
                We reserve the right, but have no obligation, to review or monitor any User Content. We are not responsible or liable for the review or monitoring of any User Content.
            </p>
            <p>
                We reserve the right to close, delete, edit, refuse to post, amend, modify or remove (without notice) any User Content at our sole discretion for any reason, including
                without limitation User Content that in our sole opinion breaches these Terms of Use.
            </p>
            <p>
                You agree that any information you provide to us about yourself will be true, accurate and complete, and that you will ensure that this information is kept accurate
                and up-to-date at all times.
            </p>
            <p>
                We advise you to think very carefully before posting or uploading any personally identifiable information about you on this Website.
            </p>
            <h2>Section 2 (Applies to all products and services)</h2>
            <h3>4 General</h3>
            <p>
                4.1 Access to and use of this website is subject to these Terms of Use and our {" "}
                Privacy Policy. By accessing and using this Website you agree
                to be bound by and to act in accordance with these Terms of Use and our {" "}
                Privacy Policy. If you do not agree to these Terms of Use or our {" "}
                Privacy Policy, you are not permitted to access and use this
                Website and you should cease such access and/or use immediately.
            </p>
            <p>
                4.2 If you breach any term of these Terms of Use, your right to access and use this Website shall cease immediately.
            </p>
            <p>
                4.3 We reserve the right to amend these Terms of Use from time to time without notice by amending
                this page. The amended Terms of Use will be effective from the date they are posted on this Website.
                As these Terms of Use may be amended from time to time, you should check them whenever you visit this
                Website. Your continued use of this Website will constitute your acceptance of the amended Terms of Use.
            </p>
            <p>
                4.4 We may terminate these Terms of Use and/or the products or services offered by {" "}
                <BrandName /> {" "} at any time without notice to you if,
                in our sole judgment, you breach any term or condition of these Terms of Use. Upon termination, you must
                discontinue all use of the information contained on the Site and destroy the materials you have obtained
                from {name}, including all related documentation and copies.
            </p>
            <h3>5 Our Service</h3>
            <p>
                5.1 We provide an independent online service which enables you to research and and apply for auto loans,
                credit building loans, credit cards, personal loans, financial and other products or services provided by
                third parties while using this Website. Our pre-approval service is free for you to use. We do not add any
                fees or commissions to the products or services you apply for. Instead, we will introduce you to other
                parties who provide these products or services. We are paid by charging those third party providers a fee
                and/or commission.
            </p>
            <p>
                5.2 Please be aware that nothing on this website is an endorsement by us in respect to a product or service
                we refer to. Rather, this website holds lists of recommendations that can help advise various products and
                services, and information is provided for general purposes only. Information should not be relied upon,
                as it is provided for you to select the product or service that you feel is most appropriate to meet your needs.
                You should always check the suitability, adequacy and appropriateness of the product or service that is of
                interest to you and it is your sole decision whether to obtain or refrain from obtaining any product or service.
                If you are in any doubt as to the suitability, adequacy or appropriateness of any product or service referred
                to on this website, we suggest that you seek independent professional advice before you obtain any product or
                service via this website.
            </p>
            <p>
                5.3 Please be aware that nothing on this website is, or shall be deemed to constitute, an offer by us or any
                third party to sell to you any product or service. Nor will we enter into any contract with you in respect of
                any product or service. By submitting your details, you are making an offer to obtain the relevant product or
                service from the relevant third party on its terms and conditions that may be accepted or rejected. The contract
                for the product or service will only be concluded once your offer has been accepted. Please note that acceptance
                of your payment where possible on this Website does not constitute acceptance of your offer. You will receive
                confirmation when your offer has been accepted.
            </p>
            <p>
                5.4 We aim to provide uninterrupted access to this Website but we give no warranty as to the uninterrupted
                availability of this Website. We reserve the right to suspend, restrict or terminate your access to this
                Website at any time.
            </p>
            <p>
                5.5 We reserve the right to add, amend, delete, edit, remove or modify any information, content, material or data
                displayed on this Website and without notice from time to time.
            </p>
            <h3>6 Permitted Use</h3>
            <p>
                6.1 You are only permitted to access and use this Website for your personal, non-commercial purposes, meaning
                this Website may only be accessed and used directly by a private individual or by a business to seek a product
                or service directly for that individual or business and on their own behalf. Access to and use of this website
                other than for your personal, non-commercial purposes is strictly prohibited.
            </p>
            <p>
                6.2 You are not permitted to use this Website:
            </p>
            <ul>
                <li>In any unlawful, fraudulent or commercial manner.</li>
                <li>
                    To harm, threaten, abuse, embarrass, defame, libel, intimidate or harass another person, or in a way that invades
                    another person&apos;s privacy or is obscene, offensive, hateful, indecent, inappropriate, objectionable, unacceptable,
                    discriminatory or damaging as determined by us.
                </li>
                <li>To create, check, confirm, update, modify or amend your own or another person&apos;s databases, records or directories.</li>
                <li>To tamper with, modify, reverse engineer or amend any part of this Website.</li>
                <li>
                    In a way that interferes with, disrupts or imposes an unreasonable or disproportionately large burden on our communications
                    and technical systems as determined by us.
                </li>
                <li>
                    Using any automated software, process, program, robot, web crawler, spider, data mining, trawling or other &apos;screen scraping&apos;
                    software, process, program or system.
                </li>
            </ul>
            <p>
                6.3 You may operate a link to this website provided you do so in a way that is fair and legal and does not damage our reputation
                or take advantage of it, as determined by us. You must not operate a link to this website in such a way as to suggest or imply
                any form of association, approval or endorsement by us. We reserve the right to require you to immediately remove any link to
                this Website at any time and we may withdraw any linking permission at any time.
            </p>
            <h3>7 Intellectual property rights</h3>
            <p>
                7.1 The copyright in the information, content, material or data displayed on this Website belongs to us or our licensors. You may
                temporarily print, copy, download or store extracts of information, content, material or data displayed on this Website for your
                own personal use, subject to the following conditions:
            </p>
            <ul>
                <li>
                    It may not be used for any commercial purposes and may not be commercially exploited, published, distributed, extracted,
                    reutilized or reproduced without our prior written consent.
                </li>
                <li>
                    You may not use any automated software, process, program or system, robot, web crawler, spider, data mining, trawling or
                    other &apos;screen scraping&apos; software, process, program or system.
                </li>
                <li>It may not be sold or transferred to any third party.</li>
                <li>The copy must retain any copyright or other intellectual property notices contained in the original material.</li>
                <li>
                    Images displayed on this Website are protected by copyright and may not be reproduced or appropriated in any manner
                    without the prior written consent of their respective owner(s).
                </li>
                <li>
                    No logos, trade marks or service marks displayed on this Website may be printed or downloaded, except as part of the
                    text of which they form part.
                </li>
                <li>You must not modify the paper or digital copies of such information, content, material or data.</li>
                <li>
                    It may not be excerpted, utilized, used, reproduced, published, reformatted and/or displayed on any other website without
                    our prior written consent.
                </li>
                <li>The status of us and our licensors as the authors of such information, content, material or data must be acknowledged.</li>
            </ul>
            <p>
                7.2 All intellectual property rights including, without limitation, all copyright, design rights, patents, inventions, logos,
                business names, trading names, service marks and trademarks, internet domain names, moral rights, rights in databases, data,
                source codes, software, specifications, know how, processes and business methods (in all cases whether registered or unregistered
                and including all rights to apply for registration) in and relating to this Website (including information, content, material
                or data displayed on it) belong to us or our licensors and all such rights are reserved.
            </p>
            <p>
                7.3 Except as set out elsewhere in these Terms of Use, none of the intellectual property rights belonging to us or our licensors
                in and relating to this website (including information, content, material or data displayed on it) may be used, copied, modified,
                published, extracted, utilized, transmitted, displayed, sold, excerpted, reverse engineered, made available, reproduced,
                reformatted or distributed by you without our prior written consent.
            </p>
            <p>
                7.4 All rights in {name} are owned by us.
            </p>
            <p>
                7.5 You shall retain ownership of any views, opinions, reviews, ratings, comments, content or material you submit, display, distribute,
                upload, post, share, publish or otherwise make publicly available on or through this Website (&quot;User Content&quot;). You grant us a perpetual,
                irrevocable, transferable, worldwide, royalty free and unlimited license to use, modify, keep, share, sell, save, copy, distribute,
                publish, display, excerpt, reproduce, utilize, extract, make available and transmit such User Content in any manner and for any purpose.
                Sections 5, 6 and 7 are important and you should read them carefully as they exclude or limit our liability to you and detail your
                responsibilities.
            </p>
            <h3>8 Limits to our liability</h3>
            <p>
                8.1 Nothing in these Terms of Use excludes or limits our liability for death or personal injury caused by our negligence or for our
                fraud or fraudulent misrepresentation.
            </p>
            <p>
                8.2 While we use reasonable endeavors to ensure information on this website is accurate, up to date and will correct errors after
                becoming aware - we do not check, monitor, review, verify or endorse, and are not responsible or liable for any loss or damage you
                may suffer or incur in connection with, any information, content, material or data collected from or provided by third parties which
                is displayed on or is otherwise available from this Website or any third party websites. Such information, content, material or data
                may be inaccurate, incomplete or out of date. It is your responsibility to check that such information, content, material or data is
                accurate, complete, correct and up to date.
            </p>
            <p>
                8.3 Any views, opinions, advice, reviews, ratings or comments on this website or any third party websites which are made by third
                parties (including User Content as described in paragraph 4.5) do not represent our views, opinions or advice, should not be relied
                upon by you and are not checked, monitored, reviewed, verified or endorsed by us. We are not responsible or liable for any loss or
                damage you may suffer or incur in connection with such views, opinions, advice, reviews, ratings or comments including in relation
                to their accuracy, truthfulness or completeness.
            </p>
            <p>
                8.4 We do not give any warranty or guarantee that this Website, any third party website or any information, content, material or
                data on this Website or any third party website is free from viruses, spyware, malicious software, trojans, worms, logic bombs or
                anything else which may have a contaminating, harmful or destructive effect on any technology. You must ensure that you use appropriate
                virus checking software. We are not responsible or liable for any loss or damage you may suffer or incur in this regard were caused by
                any third party.
            </p>
            <p>
                8.5 We are not responsible or liable for any loss or damage you may suffer or incur in connection with your use of this Website which
                is caused by any event beyond our reasonable control including the electronic transmission of information, content, material and data
                over the internet and the interception or decryption of it by others.
            </p>
            <p>
                8.6 We are not responsible or liable for any indirect loss or damage you may suffer or incur in connection with your use of this website
                (including any loss of savings you expect to make, loss of business or business opportunity, or loss of profit or revenue) or for any
                loss or damage you may suffer or incur in connection with your use of this Website which was not foreseeable by us when you used this
                Website.
            </p>
            <p>
                8.7 We are not responsible or liable for any loss or damage you may suffer or incur in connection with any inability to access and use
                this Website for any reason.
            </p>
            <h3>9 Disclaimer of Warranties</h3>
            <p>
                9.1 Use of this website and/or the services is at your own risk. Neither the Materials, Submissions nor the Third Party Content have
                been verified or authenticated in whole or in part by {name}, and they may include inaccuracies or typographical or other
                errors. {name} does not warrant the accuracy or timeliness of the Materials, Submissions or the Third Party Content contained
                on this Site. {name} has no liability for any errors or omissions in the Materials, Submissions and/or the Third Party Content,
                whether provided by {name} or our licensors.
            </p>
            <p>
                {name}, FOR ITSELF AND ITS LICENSORS, MAKES NO EXPRESS, IMPLIED OR STATUTORY REPRESENTATIONS, WARRANTIES, OR GUARANTEES IN CONNECTION
                WITH THIS SITE, THE SERVICES, ANY MATERIALS, SUBMISSIONS OR THIRD PARTY CONTENT, RELATING TO THE QUALITY, SUITABILITY, TRUTH, ACCURACY
                OR COMPLETENESS OF ANY INFORMATION OR MATERIAL CONTAINED OR PRESENTED ON THIS SITE, INCLUDING WITHOUT LIMITATION THE MATERIALS, THE
                THIRD PARTY CONTENT AND THE SUBMISSIONS OF OTHER USERS OF THIS SITE. UNLESS OTHERWISE EXPLICITLY STATED, TO THE MAXIMUM EXTENT PERMITTED
                BY APPLICABLE LAW, THIS SITE, THE SERVICES, MATERIALS, THIRD PARTY CONTENT, SUBMISSIONS, AND ANY INFORMATION OR MATERIAL CONTAINED OR
                PRESENTED ON THIS SITE IS PROVIDED TO YOU ON AN “AS IS,” “AS AVAILABLE” AND “WHERE-IS” BASIS WITH NO WARRANTY OF IMPLIED WARRANTY OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS. {name} DOES NOT PROVIDE ANY WARRANTIES
                AGAINST VIRUSES, SPYWARE OR MALWARE THAT MAY BE INSTALLED ON YOUR COMPUTER.
            </p>
            <h3>10 Exclusion of our liability in respect of third party advice, assistance, websites and products or services</h3>
            <p>
                10.1 You can apply for a number of products and services via this website. These products and services are not provided by us but are
                instead provided by third parties over whom we do not have control. It is your responsibility to satisfy yourself that you wish to
                obtain any product or service before doing so. We are not responsible or liable for any loss or damage you may suffer or incur in
                connection with any product or service you obtain after using this Website or for any acts, omissions, errors or defaults of any
                third party in connection with that product or service.
            </p>
            <p>
                10.2 Please be aware that the information and descriptions of products and services on this website may not represent the complete
                descriptions of all the features and terms and conditions of those products and services. You must ensure that you carefully read
                all the features and terms and conditions of any product or service before applying.
            </p>
            <p>
                10.3 If you apply for and obtain any product or service, you will be contracting with a Third Party who will be providing that product
                or service to you on their own terms and conditions. It is your responsibility to ensure that you understand and agree with those terms
                and conditions before entering into a contract to obtain that product or service. We are not responsible or liable for any loss or damage
                you may suffer or incur in connection with the terms and conditions applying to any contract entered into by you with any third party in
                relation to any product or service or for any acts, omissions, errors or defaults of any third party in connection with those terms and
                conditions.
            </p>
            <p>
                10.4 We provide links from this Website and from emails sent to you as part of our service to the websites of third parties. These websites
                are owned and operated by Third Parties over whom we do not have control. You access and use these third party websites at your sole risk
                and your sole discretion. You are solely responsible for any use of these third party websites and for any decision to obtain or refrain
                from obtaining any of the products or services available on such Third Party websites. Any links to third party websites are provided for
                your interest and convenience only. We do not endorse, recommend or accept responsibility for such third parties, their products or services,
                their websites or for any information, opinions or views given or advice provided by such third parties (whether on their websites or otherwise).
                We are not responsible or liable for any loss or damage you may suffer or incur in connection with your use of any third party websites or for
                any acts, omissions, errors or defaults of any Third Party in connection with their website.
            </p>
            <p>
                10.5 Your use of any third party website will be governed by the terms and conditions of use and privacy policy applicable to that website.
                Such terms and conditions of use and privacy policy will be different from these Terms of Use and our Privacy Policy. It is your responsibility
                to ensure that you understand and agree with the terms and conditions of use and privacy policy of any Third Party website before using that website.
                We are not responsible or liable for any loss or damage you may suffer or incur in connection with the terms and conditions of use or the privacy
                policy applying to any Third Party website or for any acts, omissions, errors or defaults of any third party in connection with those terms and
                conditions of use and/or privacy policy.
            </p>
            <p>
                10.6 Any views, opinions, advice or assistance which is given or provided to you by a Third Party after you have used this website do not
                represent our views, opinions, advice or assistance and are not checked, monitored, reviewed, verified or endorsed by us. We do not endorse,
                recommend or take responsibility for any third party who provides you with any views, opinions advice or assistance. You act or refrain from
                acting on any third party&apos;s views, opinions, advice or assistance at your sole risk and sole discretion and you are solely responsible for any
                decision to act or refrain from acting on such views, opinions, advice or assistance. We are not responsible or liable for any loss or damage
                you may suffer or incur in connection with such views, opinions, advice or assistance including in relation to their accuracy, truthfulness or
                completeness or for any acts, omissions, errors or defaults of any third party in connection with such views, opinions, advice or assistance.
            </p>
            <p>
                10.7 Please be aware that while this website provides information on a wide range of products or services, there may be other products or services
                available on the market which are not shown on this website and which may be more appropriate or suitable for you than those shown here.
            </p>
            <h3>11 Your responsibilities</h3>
            <p>
                11.1 You must take all reasonable precautions (including using appropriate virus checking software) to ensure that any information, content, material
                or data you provide (including User Content as described in paragraph 4.5) is free from viruses, spyware, malicious software, trojans, worms, logic
                bombs and anything else which may have a contaminating, harmful or destructive effect on any part of this website or the websites of third parties
                or any other technology.
            </p>
            <p>
                11.2 You may complete a registration process as part of your use of this website which may include the creation of a username, password and/or other
                identification information. Any username, password and/or other identification information must be kept confidential by you and must not be disclosed
                to, or shared with, anyone. Where you do disclose to or share with anyone your username, password and/or other identification information, you are
                solely responsible for all activities undertaken on this Website using your username, password and/or other identification information.
            </p>
            <p>
                11.3 You must check and ensure that all information, content, material or data you provide on this website is correct, complete, accurate and
                not misleading and that you disclose all relevant facts. We do not accept any responsibility or liability for any loss or damage you may suffer
                or incur if any information, content, material or data you provide on this Website is not correct, complete and accurate or if it is misleading
                or if you fail to disclose all relevant facts.
            </p>
            <p>
                11.4 Before you obtain any product or service from a Third Party, you must check all of the information, content, material or data held by the
                Third Party about you to ensure it is correct, complete, accurate and not misleading and that you have disclosed all relevant facts. It is your
                responsibility to identify and correct any mistakes or errors in the information, content, material or data held by the third party about you
                before you obtain any product or service. Failure to do so could invalidate the product or service provided by the third party. We do not accept
                any responsibility or liability for any loss or damage you may suffer or incur if any information, content, material or data held by the third
                party about you is not correct, complete and accurate or if it is misleading or if you have failed to disclose all relevant facts.
            </p>
            <p>
                11.5 You must get permission from any other person about whom you propose to provide information before you provide it. In submitting any other
                person&apos;s details, you are confirming to us that you have their permission to do so and that they understand how their details will be used.
            </p>
            <p>
                11.6 You are solely responsible and liable for your conduct on this Website and for your User Content. You must ensure that:
            </p>
            <ul>
                <li>You own your User Content or otherwise have the right to grant the license set out in paragraph 4.5.</li>
                <li>
                    Your User Content does not violate or infringe any privacy rights, intellectual property rights (such as copyright, database rights and trademarks)
                    or other rights of any third party (including any right of confidentiality).
                </li>
                <li>Your User Content does not violate or infringe any law.</li>
                <li>Your User Content is true, complete, accurate and not misleading where it relates to facts, or is genuinely held where it relates to an opinion.</li>
                <li>
                    Your User Content is not harmful, fraudulent, threatening, defamatory, embarrassing, distressing, infringing, abusive, inflammatory, intimidating,
                    harassing, libelous, stalking, profane, obscene, indecent, inappropriate, hateful, discriminatory or racially, ethnically, sexually or otherwise
                    objectionable.
                </li>
            </ul>
            <p>
                11.7 You agree that you will be liable to us for any damage, loss, claim, demand, liability or expense (including reasonable legal fees) that we may
                suffer or incur arising out of or in connection with your conduct on this Website and/or your User Content.
            </p>
            <h3>12 Privacy</h3>
            <p>
                12.1 We are committed to protecting your privacy and we treat your privacy very seriously. We process information about you in line with our Privacy Policy.
                By using this Website, you agree to the way in which we process and deal with your personal information.
            </p>
            <h3>13 Miscellaneous</h3>
            <p>
                13.1 This website is only intended for use by residents of Canada. We make no warranty or representation that any product or service referred
                to on this Website and/or any service we provide is available or otherwise appropriate for use outside of the Canada. If you choose to use this
                Website from locations outside of Canada, you do so at your sole risk and you are responsible for compliance with all applicable local laws.
            </p>
            <p>
                13.2 If any provision of these Terms of Use is held to be unlawful, invalid or unenforceable, that provision shall be deemed deleted from these
                Terms of Use and the validity and enforceability of the remaining provisions of these Terms of Use shall not be affected.
            </p>
            <p>
                13.3 These Terms of Use constitute the entire agreement between you and us relating to your access to and use of this Website and supersedes
                any prior agreements (including any previous terms of use of this Website).
            </p>
            <p>
                13.4 No failure or delay by us in exercising any right under these Terms of Use will operate as a waiver of that right nor will any single
                or partial exercise by us of any right preclude any further exercise of any right.
            </p>
            <h3>14 Governing law</h3>
            <p>
                These Terms of Use and your use of the Site are governed in all respects by the laws of the Province of Ontario, without giving effect to
                any principles of conflicts of laws. Any dispute concerning the Site or these Terms of Use shall be subject to the exclusive venue of a
                court of competent jurisdiction in the Province of Ontario.
            </p>
            <p>
                In the event that a court of competent jurisdiction holds any provision of these Terms of Use to be invalid or unenforceable, then such
                provision shall be severable from and shall not affect the validity or enforceability of the remaining provisions.
            </p>
        </div>
    );
};

export default TermsOfUseContent;
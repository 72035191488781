"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecaptchaReadyPromise = void 0;
class RecaptchaReadyPromise {
    promise;
    resolve;
    isReady;
    constructor() {
        this.isReady = false;
        this.promise = new Promise((resolve) => {
            this.resolve = resolve;
            if (this.isReady)
                resolve();
        });
    }
    ready = () => {
        this.isReady = true;
        if (this.resolve)
            this.resolve();
    };
    then(cb) {
        this.promise.then(cb);
        return this;
    }
}
exports.RecaptchaReadyPromise = RecaptchaReadyPromise;

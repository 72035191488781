import React, { useState, useMemo } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import PhoneLink from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";

import { config } from "config";
import { formatPhoneNumber } from "@clcdev/react-core/lib/utils";
import BaseModal from "./BaseModal";
import ContinueButton from "@components/ContinueButton";

const useStyles = makeStyles((theme) => ({
    textBox: {
        position: "absolute",
        right: "11px",
        width: "fit-content",
    },
    questionStyle: {
        fontSize: "12.1px",
        lineHeight: "1.32",
        color: theme.palette.blueGrey,
        letterSpacing: "2px",
    },
}));

const useModalContainerStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "300px",
        borderRadius: "20px",
        background: theme.palette.white,
        padding: "20px",
        outline: 0,
        margin: "auto",
        position: "relative",
        top: "140px",
    },
}));

const usePhoneStyles = makeStyles({
    root: {
        fontSize: "20.1px",
        letterSpacing: "0.25px",
    },
});

const HelpIconContainer = withStyles((theme) => ({
    root: {
        display: "flex",
        position: "absolute",
        right: "11px",
        top: "15px",

        "&:hover": {
            cursor: "pointer",
        },
    },
}))(Box);

const HelpIcon = withStyles((theme) => ({
    root: {
        fill: theme.palette.primary.main,
    },
}))(HelpOutlineRoundedIcon);

const ContactUs = () => {
    const { phoneNumber } = config.brandInfo;
    const formattedNumber = formatPhoneNumber(phoneNumber);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const styles = useStyles();
    const phoneStyles = usePhoneStyles();
    const modalContainerStyles = useModalContainerStyles();

    const linkProps = useMemo(() => ({
        underline: "none",
        href: `tel:+${phoneNumber}`,
    }), [phoneNumber]);

    const ContactUsContent = useMemo(() => (
        <PhoneLink
            {...linkProps}
        >
            {formattedNumber}
        </PhoneLink>
    ), [formattedNumber, linkProps]);

    const ContactUsCta = useMemo(() => (
        <PhoneLink
            {...linkProps}
        >
            <ContinueButton
                text={"Call Now"}
                overrideWidth="100% !important"
            />
        </PhoneLink>
    ), [linkProps]);

    return (
        <React.Fragment>
            <Hidden smUp>
                <HelpIconContainer onClick={openModal}>
                    <HelpIcon />
                </HelpIconContainer>
            </Hidden>
            <BaseModal
                classes={modalContainerStyles}
                open={isModalOpen}
                handleClose={closeModal}
                closeAfterTransition
                title={"Contact Us"}
                content={ContactUsContent}
                ctaComponent={ContactUsCta}
            />
            <Hidden xsDown>
                <Box
                    display="flex"
                    flexDirection="column"
                    textAlign="right"
                    className={styles.textBox}
                >
                    <Typography className={styles.questionStyle}>
                        QUESTIONS?
                    </Typography>
                    <PhoneLink
                        color="primary"
                        classes={phoneStyles}
                        {...linkProps}
                    >
                        {formattedNumber}
                    </PhoneLink>
                </Box>
            </Hidden>
        </React.Fragment>
    );
};

export default ContactUs;
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validate = exports.useRecaptchaWidget = exports.recaptchaReady = void 0;
const widget_1 = require("./widget");
const validate_1 = require("./validate");
const ssr_1 = require("./util/ssr");
var recaptchaReady_1 = require("./recaptchaReady");
Object.defineProperty(exports, "recaptchaReady", { enumerable: true, get: function () { return recaptchaReady_1.recaptchaReady; } });
class RecaptchaImpl {
    args = {};
    validator;
    init(args) {
        this.args = args;
        this.validator = (0, validate_1.getRecaptchaValidator)();
    }
    validate = (action) => {
        if ((0, ssr_1.isSSR)()) {
            return {};
        }
        if (this.args.explicit) {
            throw new Error("You cannot use the global validation if the Recaptcha API is set to `render=explicit`");
        }
        if (!this.validator) {
            this.validator = (0, validate_1.getRecaptchaValidator)();
        }
        return this.validator({
            action: action || this.args.siteAction,
            apiUrl: this.args.apiUrl,
            key: this.args.siteKey,
            force: false,
        });
    };
    useRecaptchaWidget = ({ action, autoValidate = true } = {}) => {
        if (!this.args.explicit) {
            throw new Error("You cannot use a Recaptcha widget if the API has not been initialized with `render=explicit`");
        }
        const widget = (0, widget_1.useRecaptchaWidget)({
            action: action || this.args.siteAction,
            siteKey: this.args.siteKey,
            apiUrl: this.args.apiUrl,
            autoValidate,
        });
        return widget;
    };
}
const recaptcha = new RecaptchaImpl();
const useRecaptchaWidget = recaptcha.useRecaptchaWidget;
exports.useRecaptchaWidget = useRecaptchaWidget;
const validate = recaptcha.validate;
exports.validate = validate;
exports.default = recaptcha;

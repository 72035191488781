import storedConfig from "__BUILD_CONFIG__";
import template from "lodash.template";
import get from "lodash.get";
import has from "lodash.has";

const processConfig = storedConfig();

const useInterpolation = [
    "title",
    "description",
    "body",
    "body1",
    "body2",
];

const wrapKeys = [
    "nextStep",
];

const restrictedKeys = [
    "steps",
];

const interpolate = (val) => (typeof val === "string"
    ? template(val)(processConfig)
    : val
);

class ConfigImpl {
    constructor() {
        this.fetchState = () => ({});

        this.config = {
            ...processConfig,
            steps: Object.entries(processConfig.steps).reduce((stepAcc, [stepKey, stepSettings]) => {
                const newStepSettings = {};
                Object.entries(stepSettings).forEach(([settingsKey, settingsVal]) => {
                    const processStepVal = this.processStepVal.bind(this);
                    if (wrapKeys.includes(settingsKey)) {
                        newStepSettings[settingsKey] = (overrideState = {}) => processStepVal(settingsKey, settingsVal, overrideState);
                    } else if (typeof stepSettings[settingsKey] === "function") {
                        Object.defineProperty(newStepSettings, settingsKey, {
                            get() {
                                return processStepVal(settingsKey, settingsVal);
                            },
                        });
                    } else {
                        newStepSettings[settingsKey] = processStepVal(settingsKey, settingsVal);
                    }
                });

                stepAcc[stepKey] = newStepSettings;
                return stepAcc;
            }, {}),
        };
    }

    setStateFetcher = (stateFetcher) => {
        this.fetchState = stateFetcher;
    }

    getConfigValue = (key) => {
        if (!key) {
            throw new Error("getConfigValue() called with empty key in steps config");
        }

        const rootKey = key.split(".")[0];
        if (restrictedKeys.includes(rootKey)) {
            throw new Error(`Key '${key}' is being used in the steps config, but its access is restricted`);
        }

        if (!has(this.config, key)) {
            throw new Error(`Key '${key}' does not exist in site config, but is being used in the steps!`);
        }

        return get(this.config, key, "");
    };

    processStepVal = (settingsKey, settingsVal, overrideState = {}) => {
        const valType = typeof settingsVal;
        if (!useInterpolation.includes(settingsKey) && valType !== "function") {
            return settingsVal;
        }

        if (valType === "function") {
            return this.processStepVal(
                settingsKey,
                settingsVal({ ...this.fetchState(), ...overrideState }, this.getConfigValue),
                overrideState,
            );
        }
        if (valType === "object") {
            if (Array.isArray(settingsVal)) {
                return settingsVal.map((val) => this.processStepVal(settingsKey, val, overrideState));
            } else {
                return Object.entries(settingsVal).reduce((acc, [key, val]) => {
                    acc[key] = this.processStepVal(key, val, overrideState);
                    return acc;
                }, {});
            }
        }
        return interpolate(settingsVal);
    };
}

const ConfigHandler = new ConfigImpl();
const setStateFetcher = ConfigHandler.setStateFetcher;
const config = ConfigHandler.config;

export {
    setStateFetcher,
    config,
};
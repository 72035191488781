import React from "react";
import BaseButton from "../BaseButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "200px",
        height: "48px",
        background: theme.palette.blueGrey,
        fontSize: "21px",
        lineHeight: "0.76",
        letterSpacing: "1.99px",
        padding: "16px, 37px, 16px, 37px",
        borderRadius: "24px",
        boxShadow: `0 1px 3px 0 rgba(0, 0, 0, 0.2),
                    0 2px 1px -1px rgba(0, 0, 0, 0.12),
                    0 1px 1px 0 rgba(0, 0, 0, 0.14)
        `,
        color: theme.palette.white,
        "&:hover": {
            background: theme.palette.secondary.main,
        },
        "&:active": {
            background: theme.palette.primary.main,
        },
    },
}));

const ContinueButton = (props) => {
    const styles = useStyles();
    return (
        <BaseButton
            classes={styles}
            {...props}
        />
    );
};

export default ContinueButton;

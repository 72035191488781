/* global __CONFIG_VARIANT_PATH__ __CONFIG_META_OVERRIDE__ */
// import variants from "./variants";
import { joinNullExcluded } from "./utils/strings";
import { setDefaultsOnObject } from "./utils/set-defaults";
import { defaults } from "./defaults";

/**
 * The possible values of the `variant` property
 * @typedef { keyof typeof import("./variants").default } Variants
 */

/**
 * Brand specific information and text placeholder fillers
 * @typedef {Object} BrandInfo
 * @property {string} phoneNumber
 * @property {string} contactEmail
 * @property {string} address
 * @property {string} copyright
 * @property {string} disclaimer
 * @property {string} defaultCampaignId
 */

/**
 * Collection of images to use in various parts of the site
 * @typedef {Object} ImageInfo
 * @property {string} [icon] - The path to the brand icon, for use in the
 *   manifest, relative to the config.  If undefined, uses `images/favicon.png`
 * @property {string} [logo] - The path to the brand logo.  If undefined,
 *   uses `images/logo.png`.
 * @property {string} [success] - Optional.  The path to the success page image.
 */

/**
 * The structure of the Loan App Config meta parameter
 * @typedef {Object} ConfigMeta
 * @property {BrandInfo} brandInfo - Brand specific information and text placeholder
 *  fillers
 *
 * @property {ImageInfo} [images] - The collection of images to use in the site
 *
 * @property {string} name - The full name of the brand (used as the author of the site,
 *  and the (short) name in the site manifest)
 * @property {string} [shortName] - Optional. Used in the site manifest.  If undefined,
 *  `name` will be used
 * @property {string} [title] - Optional. The title of the company (used for the rendered page
 *  titles).  If undefined, `name` will be used
 * @property {string} description - The description of the site.
 * @property {string} [reportingDomain] - Optional. The domain to allow Sentry reporting for.
 *  If undefined, the domain will be parsed from `siteUrl`
 *
 * @property {string} siteUrl - The production href for the site
 * @property {string} pathPrefix - The URL path prefix (if any) to be used in the site
 * @property {string} homeUrl - The URL path of the site's home page
 * @property {string} termsUrl - The URL path of the site's terms of use
 * @property {string} privacyPolicyUrl - The URL path of the site's privacy policy
 * @property {string} successUrl - The URL path of the site's success page (following
 *  lead submission)
 *
 * @property {boolean} [hideSuccessCtas] - A flag to determine whether or not to hide
 *  the Success page CTAs
 * @property {string} twilioVerifyMethods - Set available Twilio Verify methods offered in the app
 *  separated by commas
 * @prop {boolean} twilioVerifyRequired - A switch to determine whether the Twilio Verify step in the app
 *  can be skipped
 * @property {array} requiredPartialFields - A list of payload field names required for a partial lead post
 * @property {string} layout - The path to a custom-defined layout component for the application
 */

/**
 * The return value of the `createLoanAppConfig` function
 * @typedef {ConfigMeta & {steps: Object<string, any>, version: string}} LoanAppConfig
 * @prop {Object<string, any>} steps
 * @prop {Object<string, any>} transformers - A set of transformer functions that are applied to payload
 *  values before they are passed to Leadspedia
 * @prop {array} twilioVerifyMethods - A list of available Twilio Verify methods offered in the app
 * @prop {boolean} twilioVerifyRequired - A switch to determine whether the Twilio Verify step in the app
 *  can be skipped
 *
 * @prop {string} version
 * @prop {string} rawVersion
 * @prop {string} variant
 * @prop {string} rawVariant
 */

/**
 * Generates the Loan App config for consumption by `gatsby-theme-application`
 *
 * @param {Variants} variant - (Required) The variant of the config to use
 * @param {ConfigMeta} meta - (Required) The site meta to merge into the config
 * @returns {LoanAppConfig} - The compiled loan app config
 */
export const createLoanAppConfig = (variant, meta) => {

    if (!process.env.GATSBY_BUILD_STAGE) {
        // These values need to be set during node runtime so that checks against
        // the globals later don't throw an exception
        global.__CONFIG_VARIANT_PATH__ = global.__CONFIG_VARIANT_PATH__ || "";
        global.__CONFIG_META_OVERRIDE__ = global.__CONFIG_META_OVERRIDE__ || "";
    }

    // app variant
    const variantList = [
        "classic",
        "chat",
    ];
    const checkVariant = variant.toLowerCase();
    const getRawVariant = (variantName) => variantList.find((variant) => variantName.includes(variant));
    const rawVariant = getRawVariant(checkVariant);
    if (!rawVariant) {
        throw new Error(`[@clcdev/gatsby-theme-application] Unable to find variant type for defined variant ("${variant}"). Please check variant filename.`);
    }

    let config;
    if (
        !process.env.GATSBY_BUILD_STAGE &&
        checkVariant &&
        !meta.steps
    ) {
        const variantPath = __dirname + `/variants/${checkVariant}.js`;
        global.__CONFIG_VARIANT_PATH__ = variantPath;
        config = require(variantPath);
    } else if (__CONFIG_VARIANT_PATH__) {
        config = require(__CONFIG_VARIANT_PATH__);
    } else {
        config = meta || {};
    }

    const useConfig = Object.assign(
        {},
        config,
        meta.version && { version: meta.version },
        meta.rc && { rc: meta.rc },
    );

    if (Object.keys(useConfig).length === 0) {
        throw new Error(`[@clcdev/gatsby-theme-application] Defined variant ("${variant}") doesn't exist, or no steps defined!`);
    }
    if (!useConfig.version) {
        throw new Error(`[@clcdev/gatsby-theme-application] You must define a version in your application config`);
    }

    return (override) => {
        /**
         * The following needs to happen in the current order, because
         * of the split between Gatsby runtimes (node, webpack, browser)
         */

        // This block should only run during node runtime.  This check will cause
        // Webpack to skip entirely, and then treeshake it.
        if (!process.env.GATSBY_BUILD_STAGE) {
            // Only cache override if one is defined, and it hasn't been defined before
            // If it has been defined/cached already, it will be used further down.
            if (override && !global.__CONFIG_META_OVERRIDE__) {
                const cacheOverridePath = process.cwd() + "/.static/@clcdev/gatsby-theme-application/override.json";

                // This tells other passes that the override has been cached, and also
                // allows us to set the cache path as a webpack variable
                global.__CONFIG_META_OVERRIDE__ = cacheOverridePath;

                const fs = require("fs-extra");
                const path = require("path");
                fs.ensureDirSync(path.dirname(cacheOverridePath));
                fs.writeFileSync(cacheOverridePath, JSON.stringify(override));
            }
        }

        /**
         * For node, this variable should be set earlier by `global.<var> = global.<var> || ""`
         *   so this will not fail, even when the override hasn't been cached yet.
         * In cases where the override *has* been cached, the caching process above should be skipped
         *   and this value will be used.
         *
         * For webpack, this variable is defined in the webpack config api in `gatsby-node`,
         *   using the `global.<var>` property defined above.
         *
         * When no override was ever defined/cached, this will be an empty string literal.
         */
        if (__CONFIG_META_OVERRIDE__) {
            override = require(__CONFIG_META_OVERRIDE__);
        }

        const config = setDefaultsOnObject(
            {
                steps: useConfig.steps,
                ...meta,
                ...Object.entries(override || {}).reduce((acc, [key, val]) => (
                    val == null ? acc : Object.assign(acc, {
                        [key]: val,
                    })
                ), {}),
                transformers: useConfig.transformers || {},
                fixedHeight: useConfig.fixedHeight,

                rawVariant: rawVariant,
                variant: checkVariant,
                rawVersion: [
                    useConfig.version,
                    useConfig.rc,
                ].filter(Boolean).join("-"),
                version: [
                    checkVariant,
                    useConfig.version,
                    useConfig.rc,
                ].filter(Boolean).join("-"),
            },
            defaults,
        );
        return config;
    };
};
import { useCallback } from "react";
import { useSnackbar } from "notistack";
import { DEFAULT_TOAST_DURATION } from "../data/constants";
import { arrayify } from "../utils/objects";

export const useToast = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // Toast Errors
    const addToastError = useCallback((toastMessage, toastTimeout) => {

        const useTimeout = toastTimeout || DEFAULT_TOAST_DURATION;

        const key = enqueueSnackbar(toastMessage, {
            variant: "error",
            autoHideDuration: useTimeout,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
            },
        });

        return key;
    }, [enqueueSnackbar]);

    const removeToastError = useCallback((key) => {
        arrayify(key).forEach(closeSnackbar);
    }, [closeSnackbar]);

    return { addToastError, removeToastError };
};
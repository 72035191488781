import axios from "axios";
import Sentry from "@clcdev/gatsby-plugin-sentry";

let clientIpServiceUrl = null;

export const setClientIpServiceUrl = (url) => {
    clientIpServiceUrl = url;
};

export const getClientIp = async () => {
    try {
        const response = await axios.get(clientIpServiceUrl);
        return response.data.ip_address;
    } catch (err) {
        console.error(err);
        Sentry.captureException(err);
    }
};
export const getQueryParams = ( ...keysToFilter ) => {
    let result = {};
    let queryParamsToReturn = [ ...keysToFilter ];
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    urlParams.forEach((v, k) => {
        // no query params specified, return all
        if (queryParamsToReturn.length === 0){
            result[k] = v;
        } else {
            if (queryParamsToReturn.includes(k))
                result[k] = v;
        }
    });
    return result;
};
import { setPartialLeadApi } from "./src/partialLeads";
import { setClientIpServiceUrl } from "./src/api/get-client-ip";
import { setTwilioTokens } from "./src/api/twilio-verify";
import { base64Encode } from "./src/utils/base64-encode";
import EHawkTalon from "./src/api/ehawk-talon";
import CampaignHandler from "./src/utils/campaign-handler";
import AutocompleteReady from "./src/utils/autocomplete-ready";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import "./global-styles.css";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

const initializeGooglePlaces = () => {
    AutocompleteReady.ready();
    console.log("autocomplete ready");
};

const decodeTwilioTokens = (twilioTokens) => {
    return Object.entries(twilioTokens).reduce((acc, [key, val]) => {
        /* decoded token */
        val = window.atob(base64Encode(val));
        acc[key] = val;
        return acc;
    }, {});
};

export const onClientEntry = (_, options) => {
    const {
        verifyApiUrl,
        ...tokens
    } = options.twilio;
    const twilioTokens = decodeTwilioTokens(tokens);

    setPartialLeadApi(options.partialLeadApi);
    setClientIpServiceUrl(options.clientIpServiceUrl);
    setTwilioTokens({ verifyApiUrl, ...twilioTokens });
    // talon fingerprint init
    EHawkTalon.generateTalonFingerprint();
    // google maps places api
    window.initializeGooglePlaces = initializeGooglePlaces;
    const googleMapsPlacesApiScript = document.createElement("script");
    googleMapsPlacesApiScript.id = "google-maps-places-api";
    googleMapsPlacesApiScript.async = true;
    googleMapsPlacesApiScript.defer = true;
    googleMapsPlacesApiScript.src = `https://maps.googleapis.com/maps/api/js?key=${options.googleMapsApiKey}&libraries=places&callback=initializeGooglePlaces`;
    document.head.appendChild(googleMapsPlacesApiScript);
};

export const onRouteUpdate = async ({ location, prevLocation }, options) => {
    await CampaignHandler.onRouteChange();
};

export { wrapPageElement } from "./src/gatsby-wrap-element";
export { wrapRootElement } from "./src/gatsby-wrap-element";
import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const BaseButton = (props) => (
    <Box
        classes={props.classes}
        className={props.className}
        disabled={props.disabled}
        width={props.overrideWidth}
        clone
    >
        <Button onClick={props.onClick} >{props.text || "Continue"}</Button>
    </Box>
);

BaseButton.propTypes = {
    nextPage: PropTypes.string,
    handleSubmit: PropTypes.func,
};

BaseButton.defaultProps = {
    nextPage: "/",
    handleSubmit: () => { },
};


export default BaseButton;
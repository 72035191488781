import { phoneRegExp } from "@clcdev/react-core/lib/regexp";
import { formatDate } from "../../src/utils/format";
import { formatPhoneNumber } from "../../src/utils/format";
import { availableVerifyOpts } from "../../src/data/app";
const dayjs = require("dayjs");
const yup = require("yup");

const AGE_MINIMUM = 19;
export const version = "1.1.8";
export const rc = "";
export const fixedHeight = true;
export const transformers = {
    dob: (val) => formatDate(val),
    phoneNumber: (val) => formatPhoneNumber(val),
};
export const steps = {

    desired_vehicle: {
        title: "What type of vehicle are you looking for?",
        inputs: [
            {
                key: "desiredVehicle",
                type: "choice",
                options: [
                    {
                        label: "Car",
                        value: "car",
                    },
                    {
                        label: "Truck",
                        value: "truck",
                    },
                    {
                        label: "SUV",
                        value: "suv",
                    },
                    {
                        label: "Mini Van",
                        value: "mini van",
                    },
                ],
                validations: yup.string()
                    .required("Please select an option"),
                cy: {
                    critical: [0],
                },
            },
        ],
        nextStep: "date_of_birth",
        expectedTime: 5, //seconds
    },

    date_of_birth: {
        title: "When were you born?",
        inputs: [
            {
                key: "dob",
                label: "DD/MM/YYYY",
                type: "date",
                validations: yup.date()
                    .required("Date of Birth is required")
                    .typeError("Please enter a valid date")
                    .min(
                        dayjs(new Date(1900, 0, 1)).toDate(),
                        "Please enter a valid date",
                    )
                    .max(
                        /* use `startOf()` to set the day object to midnight UTC, same as a date only input */
                        dayjs().startOf("day").subtract(AGE_MINIMUM, "year").toDate(),
                        `Date of Birth must be ${AGE_MINIMUM} years or older`,
                    ),
                cy: {
                    correct: "01/01/1980",
                    wrong: "01/01{enter}",
                },
            },
        ],
        nextStep: "estimate_credit_rating",
        expectedTime: 20, //seconds
    },

    estimate_credit_rating: {
        title: "What would you guess your current credit is like?",
        inputs: [
            {
                key: "estimateCreditRating",
                type: "choice",
                options: [
                    {
                        label: "Great",
                        value: "great",
                    },
                    {
                        label: "Good",
                        value: "good",
                    },
                    {
                        label: "Fair",
                        value: "fair",
                    },
                    {
                        label: "Poor",
                        value: "poor",
                    },
                    {
                        label: "I'm not sure",
                        value: "I'm not sure",
                    },
                ],
                validations: yup.string().required("Please select an option"),
            },
        ],
        nextStep: "employment_status",
        expectedTime: 5,
    },

    employment_status: {
        title: "What's your current employment status?",
        inputs: [
            {
                key: "employmentStatus",
                type: "choice",
                options: [
                    {
                        label: "Employed",
                        value: "employed",
                    },
                    {
                        label: "Self-employed",
                        value: "self-employed",
                    },
                    {
                        label: "Student",
                        value: "student",
                    },
                    {
                        label: "Other",
                        value: "other",
                    },
                ],
                validations: yup.string().required("Please select an option"),
                cy: {
                    critical: [0, 2, 3],
                },
            },
        ],
        nextStep: "monthly_income",
        expectedTime: 5, // seconds
    },

    monthly_income: {
        title: "What is your current monthly income?",
        inputs: [
            {
                key: "monthlyIncome",
                label: "Monthly Income",
                type: "number",
                validations: yup.number()
                    .typeError("Monthly Income must be a number")
                    .required("Monthly Income is required")
                    .min(2000, "Monthly Income must be 2000 or more"),
                cy: {
                    correct: 4000,
                },
            },
        ],
        nextStep: "income_time",
        expectedTime: 10, // seconds
    },

    income_time: {
        title: "How long have you been earning this income?",
        inputs: [
            {
                key: "incomeTime",
                type: "choice",
                options: [
                    {
                        label: "Less than 3 months",
                        value: "less than 3 months",
                    },
                    {
                        label: "3 Months - 2 Years",
                        value: "3 months - 2 years",
                    },
                    {
                        label: "Over 2 Years",
                        value: "over 2 years",
                    },
                ],
                validations: yup.string()
                    .required("Please select an option")
                    .matches(
                        /(3 months - 2 years|over 2 years)/,
                        "Duration must be at least 3 months or more",
                    ),
                cy: {
                    critical: [0],
                },
            },
        ],
        nextStep: "employer_info",
        expectedTime: 5, // seconds
    },

    employer_info: {
        title: "Great! Who is your employer?",
        inputs: [
            {
                key: "companyName",
                label: "Employer",
                type: "string",
                validations: yup.string().required("Employer is required"),
                cy: {
                    correct: "Google",
                },
            },
            {
                key: "position",
                label: "Position",
                type: "string",
                validations: yup.string().required("Position is required"),
                cy: {
                    correct: "CSR",
                },
            },
        ],
        nextStep: "address",
        expectedTime: 15, //seconds
    },

    address: {
        title: "Alright! What's your home address?",
        inputs: [{
            key: "address",
            label: "Address",
            type: "address",
            useGrid: true, /* for manual address inputs */
            validations: yup.object({
                address: yup.string().required(),
                postalCode: yup.string().required(),
                city: yup.string().required(),
                provinceCode: yup.string().required(),
            }, "Please select valid address"),
        }],
        cy: {
            overrideNext: {
                key: "manual_address",
                continue: (cy) => {
                    cy.getCy("address_container").parent().getCy("manual").click();
                },
            },
        },
        nextStep: "residence_time",
        expectedTime: 10,
    },

    residence_time: {
        title: "How long have you been living at this address?",
        inputs: [
            {
                key: "residenceTime",
                type: "choice",
                options: [
                    {
                        label: "Less Than 2 Years",
                        value: "less than 2 years",
                    },
                    {
                        label: "Over 2 Years",
                        value: "over 2 years",
                    },
                ],
                validations: yup.string().required("Please select an option"),
                cy: {
                    critical: [0],
                },
            },
        ],
        nextStep: "name",
        expectedTime: 5, //seconds
    },

    name: {
        title: "What's your name?",
        inputs: [
            {
                key: "firstName",
                label: "First Name",
                type: "string",
                validations: yup.string()
                    .required("First Name is required")
                    .min(2, "First Name is too short")
                    .max(20, "First Name is too long"),
                cy: {
                    correct: "First Name",
                },
            },
            {
                key: "lastName",
                label: "Last Name",
                type: "string",
                validations: yup.string()
                    .required("Last Name is required")
                    .min(2, "Last Name is too short")
                    .max(20, "Last Name is too long"),
                cy: {
                    correct: "Last Name",
                },
            },
        ],
        nextStep: "email",
        expectedTime: 10, //seconds
    },

    email: {
        title: "What email would you like us to use?",
        inputs: [
            {
                key: "email",
                label: "Email Address",
                type: "string",
                validations: yup.string()
                    .required("Email Address is required")
                    .email("Must be valid email format"),
                cy: {
                    correct: "test@email.com",
                    wrong: "wrongemail.com",
                },
            },
        ],
        nextStep: "phone_number",
        expectedTime: 10, // seconds
    },

    phone_number: {
        title: "What's the best phone number to reach you?",
        inputs: [
            {
                key: "phoneNumber",
                label: "Phone Number",
                type: "tel",
                validations: yup.string()
                    .required("Phone Number is required")
                    .matches(phoneRegExp, "Phone number is not valid"),
                cy: {
                    correct: 5555555555,
                },
            },
        ],
        nextStep: (context, configValue) => {
            const verifyMethods = configValue("twilioVerifyMethods");
            return verifyMethods.length === 1
                ? "verify_code"
                : "verify_options";
        },
        expectedTime: 10, //seconds
    },

    verify_options: {
        title: "We would like to verify your identity",
        description: (context, configValue) => {
            const isVerifyRequired = configValue("twilioVerifyRequired");
            const skipMessage = isVerifyRequired
                ? ""
                : "You have the option to skip this step.";
            return `Please select one of the verification methods below. ${skipMessage}`;
        },
        inputs: (context, configValue) => {
            const isVerifyRequired = configValue("twilioVerifyRequired");
            const verifyMethods = configValue("twilioVerifyMethods");
            const verifyOptions = verifyMethods.map((method) => {
                return availableVerifyOpts[method];
            });
            if (!isVerifyRequired) {
                verifyOptions.push(availableVerifyOpts["skip"]);
            }
            return [
                {
                    key: "verifyOptions",
                    type: "choice",
                    options: verifyOptions,
                    validations: yup.string()
                        .required("Please select an option"),
                    cy: {
                        critical: [0],
                    },
                },
            ];
        },
        nextStep: (context) => (
            (context.verifyOptions === "skip")
                ? "submit"
                : "verify_code"
        ),
        expectedTime: 5, //seconds
    },

    verify_code: {
        title: (context, configValue) => {
            const verifyMethods = configValue("twilioVerifyMethods");
            if (verifyMethods.length === 1) {
                switch (verifyMethods[0]) {
                    case "sms":
                        return `Enter the verification code sent to ${context.phoneNumber} via SMS`;
                    case "email":
                        return `Enter the verification code sent to your email: ${context.email}`;
                    case "call":
                        return `Enter the verification code you received on the phone at ${context.phoneNumber}`;
                }
            } else {
                return (context.verifyOptions === "email")
                    ? `Enter the verification code sent to your email: ${context.email}`
                    : (context.verifyOptions === "sms")
                        ? `Enter the verification code sent to ${context.phoneNumber} via SMS`
                        : `Enter the verification code you received on the phone at ${context.phoneNumber}`
                ;
            }
        },
        inputs: [
            {
                key: "verifyCode",
                type: "verify-code",
            },
        ],
        nextStep: "submit",
        expectedTime: 5, //seconds
    },

    submit: {
        title: "Let’s wrap it up!",
        inputs: (_, configValue) => [
            {
                key: "authorized",
                type: "submit-blurb",
                body1: `By clicking on the "SUBMIT" Button, I have read and accepted ${configValue("name")}’s Terms of Use. I also acknowledge that I have read the Privacy Policy and agree to the terms herein.`,
            },
        ],
        hideSubmit: false,
        continueButtonText: "Submit",
        nextStep: "success",
        expectedTime: 10,
    },

    success: {
        inputs: [
            {
                key: "success",
                type: "presentational-success",
                title: "Success!",
                body: "All done! Your application has been submitted successfully. One of our approval centres will be in contact with you shortly.",
            },
            {
                type: "presentational-success-ctas",
                compact: false,
            },
        ],
        nextStep: null,
    },
};
"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRecaptchaWidget = void 0;
const react_1 = __importStar(require("react"));
const validate_1 = require("./validate");
const styles = __importStar(require("./styles/widget.module.css"));
const ReadyPromise_1 = require("./util/ReadyPromise");
const recaptchaReady_1 = require("./recaptchaReady");
const RecaptchaWidget = ({ siteKey, setWidgetId, setRendered, className, ...rest }) => {
    const widgetRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        setRendered();
        recaptchaReady_1.recaptchaReady.then(() => {
            setWidgetId(grecaptcha.render(widgetRef.current, {
                sitekey: siteKey,
                size: "invisible",
                badge: "inline",
            }));
        });
    }, [setWidgetId, setRendered, siteKey]);
    return (react_1.default.createElement("div", { ref: widgetRef, className: className || styles.widgetInline, ...rest }));
};
const useRecaptchaWidget = (props) => {
    const { action, apiUrl, autoValidate, ...rest } = props;
    const [validate] = (0, react_1.useState)(() => (0, validate_1.getRecaptchaValidator)());
    const doValidate = (0, react_1.useCallback)(async (widgetId) => {
        return validate({
            apiUrl,
            action,
            key: widgetId,
            force: !autoValidate,
        });
    }, [validate]);
    const [widgetMeta] = (0, react_1.useState)(() => {
        let widgetId = "";
        let isRendered = false;
        const waiter = new ReadyPromise_1.RecaptchaReadyPromise();
        return {
            wait: () => new Promise((resolve) => {
                waiter.then(resolve);
            }),
            set: (id) => {
                widgetId = id;
                if (autoValidate) {
                    doValidate(id).then(() => {
                        waiter.ready();
                    });
                }
                else {
                    waiter.ready();
                }
            },
            setRendered: () => { isRendered = true; },
            get id() { return widgetId; },
            get rendered() { return isRendered; },
        };
    });
    const runValidation = (0, react_1.useCallback)(async () => (widgetMeta.rendered
        ? widgetMeta.wait().then(() => doValidate(widgetMeta.id))
        : Promise.reject()), [widgetMeta, doValidate]);
    const [Widget] = (0, react_1.useState)(() => function Widget(publicProps) {
        return (react_1.default.createElement(RecaptchaWidget, { setWidgetId: widgetMeta.set, setRendered: widgetMeta.setRendered, ...rest, ...publicProps }));
    });
    return [
        Widget,
        runValidation,
    ];
};
exports.useRecaptchaWidget = useRecaptchaWidget;

let partialLeadApi;
export const setPartialLeadApi = (v) =>
    partialLeadApi = v;

export const sendPartialLead = async (lead) => {
    if(!partialLeadApi)
        return;

    lead = {
        "First_Name": lead.firstName,
        "Last_Name": lead.lastName,
        "Email": lead.email,
        "Lead_Source": "Loan Application",
    };

    const response = await fetch(partialLeadApi, {
        method: "post",
        body: JSON.stringify(lead),
        headers: {
            "Content-Type": "application/json",
        },
    });
    const results = await response.json();

    if (response.status !== 200 || results.status !== "success") {
        throw results;
    }
    return results.id;
};
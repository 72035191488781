import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useApplicationContext } from "../state";
import NoSsr from "@material-ui/core/NoSsr";
import { config } from "config";

const useStyles = makeStyles((theme) => ({
    progressBarStyles: {
        width: "100%",
        height: "28px",
    },
    progressBackground: {
        background: "#b6bdc8",
    },
    bar: {
        background: theme.palette.primary.main,
        transition: "width 300ms ease-in",
        maxWidth: "100%",
    },
}));

const ProgressBar = (props) => {
    const {
        step, // step name
    } = props;

    const {path, setPath,  buildSchema, useStorageKey } = useApplicationContext();
    const [timeData, setTimeData] = useStorageKey("timeData", {});
    const [timeTaken, setTimeTaken] = useStorageKey("timeTaken", 0);
    const styles = useStyles();
    const isIndexStep = step === Object.keys(config.steps)[0];
    const isLastStep = step === "success";

    const addTime = (stepName, taken, left, percent) => setTimeData({
        ...timeData,
        [stepName]: { taken, left, percent },
    });

    useEffect(() => {
        try {
            setTimeTaken(path.timeTaken || 0);

            if (path.name !== step) {
                // console.warn("FINDING PATH");
                let found = false;
                for (let i in path.routes) {
                    const route = path.routes[i];
                    if (route.name === step) {
                        setPath(route);
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    // TODO: Rebuild Schema
                    buildSchema(step, timeTaken, config.steps);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }, [step, path]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!timeData[step] && path.timeTaken && path.timeLeft) {
            const progress = (path.timeTaken / (path.timeTaken + path.timeLeft)) * 100;
            addTime(step, path.timeTaken, path.timeLeft, progress);
        }
    }, [path]); //eslint-disable-line

    useEffect(() => {
        if (timeData[step]) {
            setTimeTaken(timeData[step].taken);
        }
    }, [timeData, step, setTimeTaken]);

    const getProgress = () => {
        try {
            if (isIndexStep) return 0;
            if (isLastStep) return 100;

            if (timeData[step] && timeData[step].percent)
                return timeData[step].percent;

            let left = path.timeLeft,
                taken = path.timeTaken;
            if (timeData[step]) {
                left = timeData[step].left;
                taken = timeData[step].taken;
            }
            const total = left + taken;
            let progress = (taken / total) * 100;
            return progress;
        } catch (err) {
            console.error(err);
        }
    };

    const getTimeRemaining = () => {
        let timeLeft = 0;
        if(!path) {
            console.error("Could not find current path");
        }
        if (path.timeLeft)
            timeLeft = path.timeLeft;
        if (timeData[step])
            timeLeft = timeData[step].left;

        const timeRemaining = Math.ceil(timeLeft / 60);
        if (isLastStep)
            return "Application Completed";
        if (!timeRemaining){
            return "You're almost done!";
        } else if (timeRemaining === 1){
            return `1 min`;
        } else{
            return `${timeRemaining} mins`;
        }
    };


    return (
        <Box
            className={styles.progressBarStyles}
            display="flex"
            flexDirection="column"
            mx="auto"
            mb={3}
        >
            <Box height={4} className={styles.progressBackground} overflow="hidden">
                <Box className={styles.bar} height="100%" width={`${getProgress()}%`} />
            </Box>
            <NoSsr>
                <Box textAlign="center" mt="6px">
                    <Typography
                        variant="subtitle2"
                        component="span"
                        style={{ fontSize: "15px" }}
                    >
                        {getTimeRemaining()}
                    </Typography>
                </Box>
            </NoSsr>

        </Box>
    );
};

export default ProgressBar;

import React from "react";
import { AppContextProvider } from "./state";
import { SnackbarProvider } from "notistack";

const Root = ({ children, siteOptions }) => {
    return (
        <SnackbarProvider>
            <AppContextProvider siteOptions={siteOptions}>
                {children}
            </AppContextProvider>
        </SnackbarProvider>
    );
};

export default Root;
import axios from "axios";
import Sentry from "@clcdev/gatsby-plugin-sentry";

const firePostback = async ({ url, httpMethod = "post", headers = {}, payload }) => {
    try {
        const response = await axios({
            url,
            headers,
            method: httpMethod,
            data: payload,
        });
        console.log("postback response:\n", response.data);
        return response.data;
    } catch (err) {
        console.error(err);
        Sentry.captureException(err);
    }
};

export default firePostback;

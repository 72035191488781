import { graphql, useStaticQuery } from "gatsby";

export const useSiteMetadata = () => {
    const { site } = useStaticQuery(query);
    return site.siteMetadata;
};

const query = graphql`
    {
        site {
            siteMetadata {
                hideBranding
                showContactUs
                twilioVerifyRequired
                customLayout
                disableInputTransitions
            }
        }
    }
`;
import React from "react";
import Layout from "@app-layout";

const preventOnSubmit = (e) => e.preventDefault();

const App = ({ children, ...props }) => {
    return (
        <Layout {...props}>
            <form id="form" onSubmit={preventOnSubmit} autoComplete="off">
                {children}
            </form>
        </Layout>
    );
};

export default App;
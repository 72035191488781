import React from "react";

const PrivacyPolicyContent = ({ name, url, email, address }) => {
    const BrandName = () => (!url || url === "/")
        ? <b>{name}</b>
        : <a href={url} target="_blank" rel="noopener noreferrer">{name}</a>;

    return (
        <div>
            <h1>Privacy Policy</h1>
            <p>
                <BrandName /> believes that your personal information deserves protection and
                subscribes to the privacy principles laid out in the Personal Information Protection and Electronic Documents Act (PIPEDA). Our
                Privacy Policy details certain policies implemented throughout <BrandName />,
                governing {name}&apos;s use of personally identifiable information about users of our Site and users of services available through
                this site (the “Services”).
            </p>
            <h2>Privacy policy updates</h2>
            <p>
                Due to the Internet&apos;s rapidly evolving nature, {name} may need to update this Privacy Policy from time to time. If so, {name} {" "}
                will post its updated Privacy Policy on our Site located at <BrandName /> {" "}
                and we may send registered users an email, so you are always aware of what personally identifiable information we may collect and how we
                may use this information. If any modification is unacceptable to you, you shall cease using this Site and the Services. If you do not
                cease using this Site and the Services, you will be deemed to have accepted the change.
            </p>
            <p>
                If you have questions or complaints regarding our privacy policy or practices, you may contact us the following ways:
            </p>
            <ol>
                <li>By email: <a href={`mailto:${email}`} target="_top">{email}</a></li>
                <li>By regular mail: {name}, {address}</li>
            </ol>
            <h2>Information collection and use</h2>
            <p>
                You can generally visit our site without revealing any personally identifiable information about yourself. However, in certain sections
                of this site and when you use our services, we may invite you to contact us with questions or comments, request quotes or information,
                post content and other materials, participate in a user forum or blog, or complete a registration form.
            </p>
            <p>
                Due to the nature of some of these activities, we may collect personally identifiable information that you submit such as your name,
                contact data (address, email address, phone number), gender, social insurance number, driver’s license number, income level and other
                financial information, license information, username, password, age or date of birth and employment information. We may also collect
                additional personally identifiable information that you voluntarily include in your postings or transmit with your communications to us.
                Other users will be able to view who has posted certain content or documents.
            </p>
            <p>
                We may use your personally identifiable information to deliver requested services or information, provide you, or have our Third Party
                service partners (as described in the Disclosure Section below) provide you, with notices regarding goods or services you may be
                interested in purchasing in the future, register you to use our site and services, verify your authority to enter our site and to
                improve the content and general administration of the site and our services.
            </p>
            <h2>Opting out of electronic communication</h2>
            <p>
                You may withdraw your consent to receiving electronic communications from us at any time by following the opt-out instructions provided
                in each communication or by emailing us at <a href={`mailto:${email}`} target="_top">{email}.</a>
            </p>
            <h2>Children&apos;s privacy</h2>
            <p>
                <BrandName /> recognizes the privacy interests of children and we encourage parents
                and guardians to take an active role in their children&apos;s online activities and interests. This Site is not intended for children under
                the age of 18. <BrandName /> does not target its Services or this Site to children
                under 18. {name} does not knowingly collect personally identifiable information from children under the age of 18.
            </p>
            <h2>Cookies</h2>
            <p>
                Cookies are small text files stored on a user’s hard drive containing a unique identification number used to identify your computer.
                Cookies are used on most websites and do not save any personal information. Browsers will automatically accept or reject cookies, or notify
                you each time a cookie is set by a website you visit. <BrandName /> uses
                cookies to measure traffic on our website and to track when a user accesses a partner website or views a specific offer. We use them to
                track our performance with our partners so that we may optimize our website to improve user experience. If you would like more information,
                please send us an email at <a href={`mailto:${email}`} target="_top">{email}</a>.
            </p>
            <h2>Aggregate information</h2>
            <p>
                The Site may track the total number of visitors to our Site, the number of visitors to each page of our Site, browser type, and IP addresses,
                and we may analyze this data for trends and statistics in the aggregate, but such information will be maintained, used and disclosed in aggregate
                form only and it will not contain personally identifiable information. We may use such aggregate information to analyze trends, administer the
                Site, track users&apos; movement, and gather broad demographic information for aggregate use.
            </p>
            <h2>Disclosure</h2>
            <p>
                We may provide your personally identifiable information and the data generated by cookies and the aggregate information to the vendors and
                service agencies that we may engage to assist us in providing our services to you. We may disclose your personally identifiable information
                and your communications on this site when we reasonably believe we are required to do so by law, or when we deem it appropriate or necessary
                to provide such information to law enforcement authorities.
            </p>
            <p>
                We will not sell your personally identifiable information to any company or organization except we may transfer your personally identifiable
                information to a successor entity upon a merger, consolidation or other corporate reorganization in which LowestRates.ca participates or to
                a purchaser of all or substantially all of <BrandName /> assets to which
                this site relates. When you request a quote, make an inquiry about products, services and information on this site, you are expressly choosing
                to, and opting-in to, have <BrandName /> transfer your personally identifiable
                information to our third party service partners and for such third party service partners to provide your personally identifiable information
                to other businesses who provide financial products or services (such as loans and mortgages) that match your inquiry. The Third Party service
                partners may contact you regarding your inquiry and financial products and services.
            </p>
            <h2>Links to third party sites</h2>
            <p>
                The site may provide links to other websites or resources over which <BrandName /> {" "}
                does not have control (&quot;External Websites&quot;). Such links do not constitute an endorsement by <BrandName /> {" "}
                of those external websites. You acknowledge that <BrandName /> is providing these links to you only as a
                convenience, and further agree that {name} is not responsible for the content of such External Websites. Your use of external websites
                is subject to the terms of use and privacy policies located on the linked to those sites.
            </p>
            <h2>Security</h2>
            <p>
                We may employ procedural and technological security measures that are reasonably designed to help protect your personally identifiable information
                from loss, unauthorized access, disclosure, alteration or destruction. {name} may use secure socket layer and other security measures to
                help prevent unauthorized access to your personally identifiable information.
            </p>
            <p style={{fontWeight: 900}}>
                This Privacy Policy was last updated: March 15th, 2019
            </p>
        </div>
    );
};

export default PrivacyPolicyContent;
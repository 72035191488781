// eslint-disable-next-line
import React, { useState, useMemo } from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import MuiLink from "@material-ui/core/Link";
import BaseModal from "./BaseModal";
import bgImg from "../images/Waves_2.svg";
import { useApplicationContext } from "../state";
import { config } from "config";
import TermsOfUseContent from "../data/tou-content";
import PrivacyPolicyContent from "../data/privacy-content";
import partnerCampaignsJSON from "../data/partner-campaigns.json";
import CampaignHandler from "../utils/campaign-handler";
import { useSiteMetadata } from "../hooks/use-site-metadata";

const Footer = () => {
    const styles = useStyles();
    const cid = CampaignHandler.campaignId;
    const {
        recaptcha: {
            RecaptchaWidget,
        },
    } = useApplicationContext();
    const { hideBranding } = useSiteMetadata();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(<React.Fragment />);

    const {
        brandInfo: {
            copyright,
            address,
            contactEmail,
            // disclaimer,
        },
        // termsUrl,
        // privacyPolicyUrl,
        name,
        homeUrl,
    } = config;

    const TermsNode = useMemo(() => (
        <ContentContainer>
            <TermsOfUseContent
                name={name}
                url={homeUrl}
            />
        </ContentContainer>
    ), [homeUrl, name]);

    const PrivacyPolicyNode = useMemo(() => (
        <ContentContainer>
            <PrivacyPolicyContent
                name={name}
                url={homeUrl}
                email={contactEmail}
                address={address}
            />
        </ContentContainer>
    ), [address, contactEmail, homeUrl, name]);

    const closeModal = () => setIsModalOpen(false);

    const onTermsClick = (e) => {
        setModalTitle("Terms of Use");
        setModalContent(TermsNode);
        setIsModalOpen(true);
    };

    const onPrivacyPolicyClick = (e) => {
        setModalTitle("Privacy Policy");
        setModalContent(PrivacyPolicyNode);
        setIsModalOpen(true);
    };

    return (
        <Box className={styles.container}>

            <Box className={styles.waveContainer}>
                <Box className={styles.imageContainer} />
                <Box className={styles.footerBackground} />
            </Box>

            <Container maxWidth="sm">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <RecaptchaWidget />

                    <div
                        className={styles.copyrightContainer}
                        style={{ visibility: hideBranding && "hidden" }}
                    >
                        <p className={styles.copyright}>
                            {copyright}&nbsp;
                        </p>

                        {(partnerCampaignsJSON[cid] && partnerCampaignsJSON[cid].copy) ?
                            <p className={styles.copyright}>
                                <span className={styles.copyrightDivider}>|</span>
                                &nbsp;{partnerCampaignsJSON[cid].copy}
                            </p> :
                            <></>
                        }
                    </div>

                    <div className={styles.legalContainer}>
                        <MuiLink
                            underline="none"
                            onClick={onTermsClick}
                            className={styles.a}
                        >
                            Terms of Use
                        </MuiLink>
                        <MuiLink
                            underline="none"
                            onClick={onPrivacyPolicyClick}
                            className={styles.a}
                        >
                            Privacy Policy
                        </MuiLink>
                    </div>
                </Box>
                <BaseModal
                    open={isModalOpen}
                    handleClose={closeModal}
                    closeAfterTransition
                    title={modalTitle}
                    content={modalContent}
                />
            </Container>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
        marginTop: "60px",

        [theme.breakpoints.up("sm")]: {
            marginTop: "80px",
        },
        [theme.breakpoints.up("md")]: {
            marginTop: "100px",
        },
    },

    waveContainer: {
        position: "absolute",
        width: "100%",
        padding: 0,
        margin: 0,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        zIndex: -1,
    },

    footerBackground: {
        height: "50%",
        backgroundImage: "linear-gradient(to bottom, rgba(142, 211, 255,0.16), rgba(142, 211, 255,0))",
    },

    imageContainer: {
        width: "100%",
        height: "60px",
        display: "flex",
        background: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",

        [theme.breakpoints.up("sm")]: {
            height: "90px",
        },
        [theme.breakpoints.up("md")]: {
            height: "100px",
        },
        [theme.breakpoints.up("lg")]: {
            height: "200px",
        },
        [theme.breakpoints.up("xl")]: {
            height: "300px",
        },
        [theme.breakpoints.up(2560)]: {
            height: "500px",
        },
        [theme.breakpoints.up(3250)]: {
            height: "700px",
        },
    },

    copyrightContainer: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        paddingTop: "20px",

        [theme.breakpoints.down(363)]: {
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "10px",
        },
    },

    copyright: {
        fontSize: "12px",
        color: "#9c9c9f",
        textAlign: "center",

        [theme.breakpoints.down(363)]: {
            marginTop: "0",
        },
    },

    copyrightDivider: {
        [theme.breakpoints.down(363)]: {
            display: "none",
        },
    },

    legalContainer: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        fontSize: "12px !important",
        height: "40px",
        color: theme.palette.primary.main,
    },

    a: {
        textDecoration: "none",
        color: theme.palette.primary.main,
        paddingLeft: "20px",
        paddingRight: "20px",
        fontSize: "14px",
        textAlign: "center",
        cursor: "pointer",

        [theme.breakpoints.up("md")]: {
            fontSize: "16px",
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "20px",
        },
    },
}));

const ContentContainer = withStyles((theme) => ({
    root: {
        margin: "0px 20px",
        fontSize: "15px",
    },
}))(Box);


export default Footer;
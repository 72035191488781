const inArray = (arr, name) => {
    for (let x of arr) {
        if (x.name === name)
            return true;
    }
    return false;
};

const findStep = (find, dict) => {
    // for(let step in dict) {
    //     if(dict[step], find, dict[step].title === find.title)
    //         return dict[step];
    // }
    // // console.warn(`Couldn't find ${find} in `, dict)
    // if(dict[find]) {
    //     // console.warn('Found', dict[find])
    //     return dict[find];
    // }
    return (find in dict) ? dict[find] : null;
};

const getStep = (stepname, context, steps, time) => {
    //console.log('------------------------------------------\n', stepname, steps)
    const step = findStep(stepname, steps); // steps[stepname]

    if (!stepname) {
        console.error("No step name provided");
        return;
    }

    if (!step) {
        console.error("Cannot find step:", stepname, steps);
        return;
    }


    const {
        nextStep,
        expectedTime,
        inputs,
    } = step;
    const curNextStep = nextStep();
    const timeTaken = time + expectedTime;

    const ret = {
        name: stepname,
        expectedTime,
        timeTaken: time || 0,
        timeLeft: 0,
        routes: [],
    };

    if (!curNextStep) {
        return ret;
    }

    // Adding steps to routeNames
    const includeRoute = (next, branchContext, always = false) => {
        if (next && (always || !inArray(ret.routes, next))) {
            const nextStep = getStep(next, branchContext, steps, timeTaken);
            ret.routes.push(nextStep);
        }
    };

    for (const input of inputs) {
        if (input.options) {
            for (const option of input.options) {
                const branchContext = { ...context, [input.key]: option.value };
                const next = nextStep(branchContext);
                /**
                 * By skipping duplicated routes here, future paths through
                 * the app may be affected, depending on the logic in each
                 * of the `nextStep` functions.
                 *
                 * Current timeLeft calculations differences are negligible, because
                 * there is not much variance in the path based on `nextStep` function logic.
                 * Conversely, the time taken to process all potential paths is a significant increase.
                 *
                 * If it's decided later that the time calculcation needs to be more accurate
                 * because of added variance in the potential paths, then the `false` boolean can be
                 * changed to `true`
                 */
                includeRoute(next, branchContext, false);
            }
        } else {
            includeRoute(curNextStep, context);
        }
    }

    const len = ret.routes.length;
    const sum = ret.routes.reduce((sum, route) => {
        if (!route) return sum;
        if (route.timeLeft) sum += route.timeLeft;
        if (route.expectedTime) sum += route.expectedTime;
        return sum;
    }, 0);
    ret.timeLeft = sum / len;

    return ret;
};

module.exports = {
    getStep,
};
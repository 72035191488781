// Leadspedia sub-id payload map
export const subIdMappings = {
    s1: "lpS1",
    s2: "lpS2",
    s3: "lpS3",
    s4: "lpS4",
    s5: "lpS5",
};

// twilio verify options map
export const availableVerifyOpts = {
    sms: {
        label: "SMS",
        value: "sms",
    },
    email: {
        label: "E-mail",
        value: "email",
    },
    call: {
        label: "Phone Call",
        value: "call",
    },
    skip: {
        label: "Skip",
        value: "skip",
    },
};
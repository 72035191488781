// eslint-disable-next-line
import React from "react"
import ProgressBar from "./components/ProgressBar";
import Footer from "./components/Footer";
import AppBar from "./components/AppBar";

const Layout = (props) => {
    const stepName = props.pageContext.step;
    const hideProgressBar = stepName === "terms_of_use" || stepName === "privacy_policy";
    return (
        <>
            <AppBar stepName={stepName} />
            {!hideProgressBar && <ProgressBar step={stepName} />}
            {props.children}
            <Footer />
        </>
    );
};

export default Layout;
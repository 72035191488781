import { callEhawkTalon } from "./talon-helper";

const DEFAULT_TALON_VALUE = '{"version": 5, "status": -1}';

class EhawkTalon {
    constructor(){
        this.waiting = false;
        this.promise = null;
    }

    get talonFingerprint(){
        if (this.promise){
            return this.promise;
        } else {
            return this.generateTalonFingerprint();
        }
    }

    generateTalonFingerprint(){
        if (this.waiting){
            return this.promise;
        }
        this.waiting = true;
        this.promise = callEhawkTalon()
            .then((talon) => {
                this.waiting = false;
                return talon;
            })
            .catch(() => DEFAULT_TALON_VALUE);
        return this.promise;
    }
}

const _ehawkTalon = new EhawkTalon();
const getTalonFingerprint = _ehawkTalon.talonFingerprint;

export { getTalonFingerprint };
export default _ehawkTalon;